import React, { useState, useRef } from 'react';
import { Text } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import LoginContainer from '../../containers/LoginContainer';
import { actions as userActions } from '../../store/user';
import RequestForm from './RequestForm';
import ConfirmForm from './ConfirmForm';
import useAuthCheck from '../../utils/hooks/useAuthCheck';

export default function ForgotPassword() {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canceller = useRef();
  const [codeSent, setCodeSent] = useState(false);

  const { username: usernameFromLogin = '' } = location.state || {};
  const [username, setUsername] = useState(usernameFromLogin);

  const [isLoggedIn, pathname] = useAuthCheck();

  if (isLoggedIn) {
    return <Redirect to={pathname} />;
  }

  const resetPassword = async ({ username }) => {
    setCodeSent(false);
    setUsername(username);

    canceller.current && canceller.current.cancel();

    if (username) {
      const [cancelToken, promise] = dispatch(userActions.forgotPassword(username));
      canceller.current = cancelToken;

      const response = await promise;
      const { status } = response || {};
      if (status === 200) {
        setCodeSent(true);
      }
    }
  };

  const confirmPassword = async ({ code, password }) => {
    canceller.current && canceller.current.cancel();

    if (code && password) {
      const [cancelToken, promise] = dispatch(userActions.confirmPassword(username, code, password));
      canceller.current = cancelToken;

      const response = await promise;
      const { status } = response || {};
      if (status === 200) {
        history.push('/login');
      }
    }
  };

  const subText = codeSent ? 'confirm_password_subtext' : 'forgot_password_subtext';

  return (
    <LoginContainer className="forgot-password-form" title={t('forgot_password_title')}>
      <Text className="subtext">{t(subText)}</Text>
      {!codeSent && <RequestForm username={username} onSubmit={resetPassword} />}
      {codeSent && <ConfirmForm onSubmit={confirmPassword} />}
    </LoginContainer>
  );
}
