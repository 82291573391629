import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Input, DatetimeForm } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';

import useDateLocale from '../../utils/hooks/useDateLocale';
import useTimeLocale from '../../utils/hooks/useTimeLocale';

import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';

const DateInputHolder = styled.div`
  position: relative;
  input {
    padding-right: 30px;
  }
  .date-input-time-form {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 0;
  }
  > svg {
    position: absolute;
    right: 6px;
    top: 9px;
    color: var(--colors-dark-grey);
    pointer-events: none;

    &[disabled] {
      color: var(--colors-silver);
    }
  }
`;

export default function DateInput({ value, onChange, errors, disabled }) {
  const [date, setDate] = useState(value);
  const [open, setOpen] = useState();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dLocale = useDateLocale();
  const tLocale = useTimeLocale();

  useEffect(() => {
    setDate(value);
  }, [value]);

  const toggleClick = () => setOpen(!open);
  const onDateChange = (_, date) => {
    setDate(date);
    onChange && onChange(date);
    setOpen(false);
  };

  const onKeyPress = useCallback(
    (onClick) => (e) => {
      const enterKey = 13;
      const spaceKey = 32;
      const { charCode: keyCode } = e;

      if (keyCode === enterKey || keyCode === spaceKey) {
        onClick(e);
        e.preventDefault();
        e.stopPropagation();
      }
    },
    []
  );

  const inputValue = date ? `${dLocale(date)} ${t('at')} ${tLocale(date)}` : '';

  return (
    <DateInputHolder>
      <Input
        disabled={disabled}
        label={t('consignments:quantities.placeholder.date')}
        placeholder={t('consignments:quantities.placeholder.date')}
        showLabel={false}
        onClick={toggleClick}
        onKeyPress={onKeyPress(toggleClick)}
        value={inputValue}
        errors={errors}
      />
      <CalendarIcon disabled={disabled} />
      {open && !disabled && (
        <DatetimeForm
          value={date}
          language={language}
          className="date-input-time-form"
          onCancel={() => setOpen(false)}
          onSubmit={onDateChange}
          submitText={t('apply')}
          cancelText={t('cancel')}
        />
      )}
    </DateInputHolder>
  );
}

DateInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  errors: PropTypes.any,
  disabled: PropTypes.bool,
};
