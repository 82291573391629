import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import { reducers as user } from './user';
import { reducers as consignments } from './consignments';
import { reducers as loader } from './loader';
import { reducers as alerts } from './alerts';
import { reducers as settings } from './settings';
import { reducers as popup } from './popup';

const rootReducer = combineReducers({
  loader,
  user,
  alerts,
  consignments,
  settings,
  popup,
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

function configureStore(preloadedState) {
  const middlewareEnhancer = applyMiddleware(thunk);
  const composedEnhancers = composeEnhancers(middlewareEnhancer);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}

const store = configureStore();

export default store;
