import React, { useCallback, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { DatetimeForm } from '@sevensenders/component-library';

import EditableCell from '../../../components/EditableCell';
import useDateLocale from '../../../utils/hooks/useDateLocale';
import useTimeLocale from '../../../utils/hooks/useTimeLocale';
import { ConsignmentContext } from '../../Consignments/context';
import { useTranslation } from 'react-i18next';

const StyledEditableCell = styled(EditableCell)`
  > span {
    display: block;
    width: 80%;

    &::after {
      top: -5px;
    }
  }
`;

export default function DatetimeEditCell({ value, row, column }) {
  const cellRef = useRef();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { type } = useContext(ConsignmentContext);
  const { original: rowData } = row;
  const { isEditable: isEditableCallback, onSubmit } = column;
  const hasValue = !!value;
  const editable = useMemo(
    () => isEditableCallback && isEditableCallback({ type, rowData, column }),
    [isEditableCallback, rowData, column, type]
  );

  const dLocale = useDateLocale();
  const tLocale = useTimeLocale();
  const datetime = new Date(value);
  const date = dLocale(datetime);
  const time = tLocale(datetime);

  const canClose = (contentRef, e) =>
    contentRef.current && !contentRef.current.contains(e.target) && !e.target.className.includes('7S__option');

  const onCancel = useCallback(() => {
    cellRef.current && cellRef.current.dispatchEvent(new Event('close'));
  }, [cellRef]);

  const handleSubmit = useCallback(
    (_, value) => {
      onSubmit && onSubmit(dispatch)({ type, rowData, column, value });
      onCancel();
    },
    [onSubmit, dispatch, rowData, column, onCancel, type]
  );

  return (
    <StyledEditableCell
      ref={cellRef}
      editable={editable}
      subText={hasValue ? time : ''}
      canClose={canClose}
      value={hasValue ? date : <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
    >
      <DatetimeForm
        language={language}
        value={value}
        submitText={t('submit')}
        cancelText={t('cancel')}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </StyledEditableCell>
  );
}

DatetimeEditCell.propTypes = {
  value: PropTypes.string,
  column: PropTypes.any,
  row: PropTypes.any,
};
