export function deKebabCase(str) {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function ucFirst(str) {
  return str.length > 1 ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}
