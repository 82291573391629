import React, { useMemo, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Table, TableHeaderCell, TableRow, TableRowCell } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTable } from 'react-table';

import { actions as consignmentActions } from '../../store/consignments';
import { ConsignmentContext } from '../Consignments/context';
import { columnsConfig, getHiddenColumns, getLspColumn, TYPES } from '../../config/consignments';
import { getRowComponentByStatus } from '../../utils/consignments';

import { isCurrentUserLsp } from '../../utils/user';

const TableContainer = styled.div`
  > div {
    position: ${({ hasData }) => (hasData ? 'initial' : 'relative')};
  }
`;

const ListTable = styled(Table)`
  min-height: ${({ isLoading, hasData }) => (isLoading || !hasData ? '364px' : '100px')};
  ${({ hasData }) =>
    !hasData &&
    `
    td {
      height: calc(100% - 43px);
      top: 43px;
    }
  `}

  tr:hover td:nth-of-type(2) a span:first-of-type::after {
    border-bottom: 1px solid var(--colors-sky-blue);
    content: '';
    display: block;
    width: 100%;
    position: absolute;
  }

  .Status_Blip {
    display: none;
  }
`;

const ConsignmentTable = React.memo((props) => {
  const { children, ...otherProps } = props;
  return <ListTable {...otherProps}>{children}</ListTable>;
});
ConsignmentTable.displayName = 'ConsignmentTable';
ConsignmentTable.propTypes = {
  children: PropTypes.node,
};

const RowMemo = React.memo(({ headerGroup }) => {
  const { t } = useTranslation();
  return (
    <TableRow {...headerGroup.getHeaderGroupProps()}>
      {
        // Loop over the headers in each row
        headerGroup.headers.map((column, columnKey) => (
          // Apply the header cell props
          <TableHeaderCell {...column.getHeaderProps()} style={{ width: column.width || 'auto' }} key={columnKey}>
            {t(column.render('Header'))}
          </TableHeaderCell>
        ))
      }
    </TableRow>
  );
});
RowMemo.displayName = 'Row';
RowMemo.propTypes = {
  headerGroup: PropTypes.any.isRequired,
};

function TableHeader({ headerGroups }) {
  return headerGroups.map((headerGroup, headerGroupKey) => (
    // Apply the header row props
    <RowMemo headerGroup={headerGroup} key={headerGroupKey} />
  ));
}

const tableDefaultProps = {
  role: 'table',
};

const bodyProps = {
  role: 'rowgroup',
};

function ConsignmentsTable() {
  const { t } = useTranslation();
  const canceller = useRef();
  const dispatch = useDispatch();
  const { type } = useContext(ConsignmentContext);
  const { consignments, loading, filters } = useSelector((state) => state.consignments);

  const isInbound = type === TYPES.INBOUND;

  // fetch consignments on mount
  useEffect(() => {
    canceller.current && canceller.current.cancel();
    canceller.current = dispatch(consignmentActions.getConsignments(type));
  }, [dispatch, type, filters]);

  // cancel request on unmount
  useEffect(
    () => () => {
      canceller.current && canceller.current.cancel();
      dispatch(consignmentActions.setConsignments(null));
    },
    [dispatch, canceller]
  );

  const hiddenColumns = getHiddenColumns(isInbound).concat(getLspColumn(isCurrentUserLsp()));

  const { headerGroups, rows, prepareRow } = useTable({
    initialState: { hiddenColumns },
    columns: columnsConfig,
    data: consignments || [],
  });

  const hasData = useMemo(() => consignments && consignments.length > 0, [consignments]);
  const header = useMemo(() => <TableHeader headerGroups={headerGroups} />, [headerGroups]);
  const tableProps = useMemo(
    () => ({
      isLoading: loading,
      hasData,
      hasCustomColumnWidth: true,
      header,
      loadingText: t('consignments:table.loading_text'),
      noDataTitle: t('consignments:table.no_data_title'),
      noDataSubText: t('consignments:table.no_data_subtext'),
      bodyProps,
      ...tableDefaultProps,
    }),
    [hasData, header, t, loading]
  );

  const tableRows = useMemo(
    () =>
      rows.map((row, rowKey) => {
        // Prepare the row for display
        prepareRow(row);
        const { original } = row;
        const Row = getRowComponentByStatus(original);

        return (
          <Row {...row.getRowProps()} key={rowKey}>
            {row.cells.map((cell, cellKey) => {
              return (
                <TableRowCell {...cell.getCellProps()} align="top" style={cell.column.style} key={cellKey}>
                  {cell.render('Cell')}
                </TableRowCell>
              );
            })}
          </Row>
        );
      }),
    [rows, prepareRow]
  );

  return (
    <TableContainer hasData={hasData}>
      <ConsignmentTable {...tableProps}>{tableRows}</ConsignmentTable>
    </TableContainer>
  );
}

export default React.memo(ConsignmentsTable);
