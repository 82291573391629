import { utcToZonedTime, format } from 'date-fns-tz';
import { isFuture, isValid } from 'date-fns';
import config from '../config';

const ISO8601Format = "yyyy-MM-dd'T'HH:mm:ssxxx";

/**
 * Return formatted datetime for timezone
 * @param date
 * @returns {string}
 */
export const formatDatetime = (date, dateTimeFormat = ISO8601Format) =>
  format(utcToZonedTime(date, config.defaultTimezone), dateTimeFormat, {
    timeZone: config.defaultTimezone,
  });

export const isDateFuture = (date) => isFuture(date);

export const isValidDate = (date) => isValid(date);
