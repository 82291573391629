import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    // for scroll by main
    const [main] = document.getElementsByTagName('main');
    if (main) {
      main.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
