import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import config from '../../config';
import Alerts from '../../containers/Alerts';

const PASSWORD_MESSAGE = 'validations:password.invalid';

const schema = yup.object().shape({
  code: yup
    .string()
    .matches(config.policy.code, { message: 'validations:code.invalid', excludeEmptyString: true })
    .required(),
  password: yup
    .string()
    .matches(config.policy.password, { message: PASSWORD_MESSAGE, excludeEmptyString: true })
    .required(),
  confirm_password: yup
    .string()
    .matches(config.policy.password, { message: PASSWORD_MESSAGE, excludeEmptyString: true })
    .required(),
  matchPassword: yup.bool().when(['password', 'confirm_password'], {
    is: (password, confirm_password) => password !== confirm_password,
    then: yup.bool().required('validations:password.match_fail'),
    otherwise: yup.bool(),
  }),
});

function ConfirmForm({ onSubmit }) {
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const { t, i18n } = useTranslation();

  const onFormSubmit = async ({ code, password }) => {
    onSubmit &&
      onSubmit({
        code,
        password,
      });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Alerts namespace="confirm-password" />
      <div className="inputContainer">
        <Input
          size="large"
          label={t('code')}
          name="code"
          ref={register}
          autoComplete="off"
          errors={i18n.t(errors.code?.message)}
        />
        <Input
          size="large"
          label={t('password')}
          name="password"
          type="password"
          autoComplete="new-password"
          ref={register}
          errors={i18n.t(errors.password?.message || errors.matchPassword?.message)}
          showErrorMessage={!!errors.password?.message || !!!errors.matchPassword?.message}
        />
        <Input
          size="large"
          label={t('confirm_password')}
          name="confirm_password"
          type="password"
          autoComplete="new-password"
          errors={[i18n.t(errors.confirm_password?.message), i18n.t(errors.matchPassword?.message)]}
          ref={register}
        />
      </div>
      <div className="alignCenter">
        <Button variant="primary" inline large>
          {t('update_password')}
        </Button>
      </div>
    </form>
  );
}

ConfirmForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default React.memo(ConfirmForm);
