import React, { useState, useRef, useMemo, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Text, SidePanel, Button, Loader, Input, Tooltip, rebuildTooltip } from '@sevensenders/component-library';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import schema from './validation';

import { ConsignmentContext, ConsignmentContextProvider } from '../Consignments/context';
import { SCOPE, TYPES } from '../../config/consignments';
import { actions as consignmentActions } from '../../store/consignments';
import useDateLocale from '../../utils/hooks/useDateLocale';
import useTimeLocale from '../../utils/hooks/useTimeLocale';
import {
  allInputDisabledOutbound,
  allInputDisabledInbound,
  canDismissConsignment,
  canReportDropOffQuantity,
  canReportPickupQuantity,
  canReportPlannedQuantity,
  canReportTotalParcelsQuantityInbound,
  canReportTotalParcelsQuantityOutbound,
  canUpdateDropOffTime,
  canUpdatePickupTime,
  getNumberValue,
  isBlocked,
} from '../../utils/consignments';

import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as DamageSVG } from '../../icons/claims-damage.svg';
import DateInput from '../../components/DateInput';
import { formatDatetime } from '../../utils/datetime';
import ConfirmationBox from '../../components/ConfirmationBox';

import { isCurrentUserLsp } from '../../utils/user';

const StyledSidePanel = styled(SidePanel)`
  .modal-close {
    top: 20px;
    right: auto;
    left: 24px;
    height: 24px;
    width: 24px;
    padding: 0;
    z-index: 1;
  }
  .sidePanel__closeIcon {
    display: block;
    cursor: pointer;
  }
  .sidePanel__header {
    background: var(--colors-snow);
    font-family: Radikal;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    color: var(--colors-dark-grey);
    padding: 16px;
  }
  .sidePanel__body {
    overflow: auto;
    height: calc(100vh - 74px - 64px);
    padding: 32px 48px;
  }
  .sidePanel__footer {
    padding: 16px 24px;
    border-top: 2px solid var(--colors-snow);
    background-color: #ffffff;
    text-align: right;
  }
  .sidePanel__actionButton {
    margin-left: 8px;
  }
`;

const LabelText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-weight: 600;
  margin: 32px 0 20px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const DetailHolder = styled.div`
  margin-bottom: 16px;
`;

const FlexedContainer = styled.div`
  display: flex;
  > div {
    width: ${({ autoResize, children }) =>
      children && autoResize && children.length > 1 ? `${100 / children.length}%` : '50%'};
    margin-right: 12px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:last-of-type {
    > div {
      margin-bottom: 0;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  > div {
    margin-right: 12px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &:last-of-type {
    > div {
      margin-bottom: 0;
    }
  }
`;

const FlexedText = styled(Text)`
  display: flex;
  align-items: center;
  svg {
    display: block;
    margin-left: 4px;
  }
`;

const StyledConfirmationBox = styled(ConfirmationBox)`
  .confirmationBox__header {
    padding: 14px 24px;
  }
`;

const DamageIcon = styled(DamageSVG)`
  color: var(--colors-dark-red);
  background: var(--colors-lighter-red);
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  margin-right: 16px;
`;

function Quantities({ consignment, scope, register, errors, onInputChange }) {
  const { type } = useContext(ConsignmentContext);
  const { t, i18n } = useTranslation();
  const isInbound = type === TYPES.INBOUND;

  if (!consignment) {
    return null;
  }

  const { unit_quantities } = consignment;
  const isActualScope = scope === SCOPE.ACTUAL;
  const placeholderKey = isActualScope ? 'actual' : 'planned';
  let dataKey = 'planned_quantity';
  const blocked = isBlocked(consignment);
  let canReportCallback = canReportPlannedQuantity;
  if (isActualScope) {
    if (isInbound) {
      dataKey = 'dropped_off_quantity';
      canReportCallback = canReportDropOffQuantity;
    } else {
      dataKey = 'picked_up_quantity';
      canReportCallback = canReportPickupQuantity;
    }
  }

  return (
    <FlexedContainer>
      {unit_quantities.map(({ [dataKey]: value, dispatch_unit_type: { name, short_name } }, key) => {
        const label = `consignments:quantities.placeholder.${placeholderKey}.${short_name}`;
        return (
          <div key={key}>
            <Input
              label={t(label)}
              placeholder={t(label)}
              showLabel={false}
              defaultValue={isNaN(parseInt(value)) ? '' : value}
              ref={register}
              name={`consignments:unit_quantities[${short_name}][${dataKey}]`}
              postfixText={short_name}
              postfixTitle={name}
              onChange={onInputChange}
              disabled={consignment && (blocked || !canReportCallback(consignment, short_name))}
              errors={i18n.t(errors?.['consignments:unit_quantities']?.[short_name]?.[dataKey]?.message)}
              inputMode="numeric"
              autoComplete="off"
            />
          </div>
        );
      })}
    </FlexedContainer>
  );
}

Quantities.propTypes = {
  consignment: PropTypes.any,
  scope: PropTypes.number,
  register: PropTypes.func,
  errors: PropTypes.any,
  onInputChange: PropTypes.func,
};

function ExpectedQuantities({ consignment }) {
  if (!consignment) {
    return null;
  }

  const { unit_quantities } = consignment;

  return unit_quantities.map(
    ({ expected_quantity, dispatch_unit_type: { short_name } }, key) =>
      expected_quantity && (
        <Text key={key} inline={false} secondary>
          {expected_quantity} {short_name}
        </Text>
      )
  );
}

ExpectedQuantities.propTypes = {
  consignment: PropTypes.any,
};

export default function Consignment(props) {
  const { id } = useParams();
  const canceller = useRef();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const dtLocale = useDateLocale('long');
  const tLocale = useTimeLocale();
  const [dateTime, setDateTime] = useState();
  const [showDismissConfirmation, setShowDismissConfirmation] = useState(false);
  const { consignment, loadingConsignment } = useSelector((state) => state.consignments);
  const { total_parcels_quantity, actual_pick_up_time, actual_drop_off_time } = consignment || {};
  const { type } = props;
  const isInbound = type === TYPES.INBOUND;

  const { handleSubmit, ...formProps } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema(consignment)),
  });

  useEffect(() => {
    canceller.current && canceller.current.cancel();
    if (!consignment) {
      canceller.current = dispatch(consignmentActions.getConsignment(id));
    }
  }, [dispatch, id, consignment]);

  // cancel request on unmount
  useEffect(
    () => () => {
      canceller.current && canceller.current.cancel();
      dispatch(consignmentActions.setConsignment(null));
    },
    [dispatch, canceller]
  );

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const listsPage = isInbound ? '/consignments/inbound' : '/consignments/outbound';
  const doClose = useCallback(() => history.push(listsPage), [history, listsPage]);

  const allDisabled = useMemo(
    () => !!(consignment && (isInbound ? allInputDisabledInbound(consignment) : allInputDisabledOutbound(consignment))),
    [isInbound, consignment]
  );
  const dismissDisabled = useMemo(() => !!(consignment && !canDismissConsignment(consignment)), [consignment]);

  let headerTitle = <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />;
  if (consignment) {
    const {
      address: { city: startCity },
    } = consignment?.origin_loading_location.station;
    const {
      address: { city: endCity },
    } = consignment?.destination_loading_location.station;
    const lmc = consignment?.lmc?.public_name;
    headerTitle = `${startCity} - ${endCity} ${lmc ? `(${lmc})` : ''}`;
  }

  const license = isInbound ? consignment?.vehicle_license?.drop_off : consignment?.vehicle_license?.pickup;
  const plannedSectionLabel = `consignments:${isInbound ? 'drop_off' : 'pickup'}_information`;
  const plannedLabel = `consignments:table.columns.${isInbound ? 'planned_drop_off' : 'planned_picked_up'}`;
  const actualTimeProp = isInbound ? 'actual_drop_off' : 'actual_picked_up';
  const actualLabel = `consignments:table.columns.${actualTimeProp}`;
  const pickUpOrDropOffValue = isInbound ? consignment?.planned_drop_off_time : consignment?.planned_pick_up_time;
  let plannedValue = '';

  if (pickUpOrDropOffValue) {
    const { from, until } = pickUpOrDropOffValue;
    const start = new Date(from);
    const end = new Date(until);

    plannedValue = `${dtLocale(start)} ${t('at')} ${tLocale(start)} - ${tLocale(end)}`;
  }

  const time = isInbound ? actual_drop_off_time : actual_pick_up_time;
  const actualTime = (consignment && time && new Date(time)) || '';
  const canUpdateTime = useMemo(
    () => consignment && (isInbound ? canUpdateDropOffTime(consignment) : canUpdatePickupTime(consignment)),
    [consignment, isInbound]
  );

  const onInputChange = useCallback((value, _, event) => {
    event.target.value = getNumberValue(value);
  }, []);
  formProps.onInputChange = onInputChange;

  const isTotalParcelQtyDisabled = useMemo(() => {
    if (!consignment) {
      return true;
    }
    return !(isInbound
      ? canReportTotalParcelsQuantityInbound(consignment)
      : canReportTotalParcelsQuantityOutbound(consignment));
  }, [isInbound, consignment]);

  const onFormSubmit = (data) => {
    if (allDisabled) return;

    const { quantities } = consignment || {};
    const formData = { ...data['consignments:unit_quantities'] };
    const updateData = { unit_quantity_list: {} };

    if ('actual_drop_off' in formData && formData.actual_drop_off) {
      updateData.actual_drop_off_time = formatDatetime(formData.actual_drop_off);
      delete formData.actual_drop_off;
    }

    if ('actual_picked_up' in formData && formData.actual_picked_up) {
      updateData.actual_pick_up_time = formatDatetime(formData.actual_picked_up);
      delete formData.actual_picked_up;
    }

    if ('total_parcels_quantity' in formData) {
      updateData.total_parcels_quantity = formData.total_parcels_quantity;
    }

    Object.keys(formData)
      .filter((quantityType) => {
        const quantity = formData[quantityType];
        return (
          quantity &&
          (!isNaN(quantity.dropped_off_quantity) ||
            !isNaN(quantity.picked_up_quantity) ||
            !isNaN(quantity.planned_quantity))
        );
      })
      .forEach((quantityType) => {
        const quantity = quantities && quantityType in quantities && quantities[quantityType];
        if (quantity) {
          const updatedFormData = { ...formData[quantityType] };
          Object.keys(updatedFormData).forEach((key) => {
            if (isNaN(parseInt(updatedFormData[key]))) {
              delete updatedFormData[key];
            }
          });

          updateData.unit_quantity_list[quantityType] = {
            ...quantity,
            ...updatedFormData,
          };
        }
      });

    dispatch(consignmentActions.updateConsignmentData(consignment, updateData));
  };

  const onDismissConsignment = () => {
    if (dismissDisabled) return;
    const [promise] = dispatch(consignmentActions.dismissConsignment(consignment));

    promise.finally(() => {
      setShowDismissConfirmation(false);
    });
  };

  // filter duplicate offerRelations by name
  const filteredOfferRelations = consignment?.relation.offer_relations.filter(
    (obj, index) =>
      consignment?.relation.offer_relations.findIndex(
        (item) => item.shipper.public_name === obj.shipper.public_name
      ) === index
  );

  return (
    <ConsignmentContextProvider value={{ ...props }}>
      <Tooltip id="globalTooltip" tip="" place="top" showByDefault={false} />
      <StyledSidePanel
        allowTrapFocus={!showDismissConfirmation}
        show={true}
        onClose={doClose}
        closeIcon={<CloseIcon className="sidePanel__closeIcon" />}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <h1 className="sidePanel__header">{headerTitle}</h1>
          <div className="sidePanel__body">
            <LabelText small secondary inline={false}>
              {t(plannedSectionLabel)}
            </LabelText>
            <GridContainer>
              <DetailHolder>
                <Text inline={false}>{t('consignments:table.columns.loading_reference')}</Text>
                <Text inline={false} secondary>
                  {consignment?.reference_id}
                </Text>
              </DetailHolder>
              {isCurrentUserLsp() && (
                <DetailHolder>
                  <Text inline={false}>{t('consignments:table.columns.shipper_public_name')}</Text>
                  <Text inline={false} secondary>
                    {filteredOfferRelations?.map(function (item, i) {
                      return (
                        <Text secondary inline={false} key={i}>
                          {' '}
                          {item.shipper.public_name}{' '}
                        </Text>
                      );
                    })}
                  </Text>
                </DetailHolder>
              )}
              <DetailHolder>
                <Text inline={false}>{t(plannedLabel)}</Text>
                <Text inline={false} secondary>
                  {plannedValue}
                </Text>
              </DetailHolder>
              {isInbound && (
                <DetailHolder>
                  <Text inline={false}>{t('consignments:table.columns.planned_qty')}</Text>
                  <ExpectedQuantities consignment={consignment} />
                </DetailHolder>
              )}
              <DetailHolder>
                <Text inline={false}>{t('consignments:table.columns.lmc_fullname')}</Text>
                <Text inline={false} secondary>
                  {consignment?.lmc?.public_name}
                </Text>
              </DetailHolder>
            </GridContainer>

            <LabelText small secondary inline={false}>
              {t('consignments:freight_forwarder')}
            </LabelText>
            <DetailHolder>
              <FlexedText inline={false}>
                {t('consignments:table.columns.license_plate')}
                <span data-for="globalTooltip" data-tip={t('consignments:license_info')}>
                  <InfoIcon width="16" height="16" />
                </span>
              </FlexedText>
              <Text inline={false} secondary>
                {license}
              </Text>
            </DetailHolder>
            <LabelText small secondary inline={false}>
              Shipping information
            </LabelText>
            {!isInbound && (
              <DetailHolder>
                <Text inline={false}>{t('consignments:table.columns.planned_qty')}</Text>
                <Quantities consignment={consignment} scope={SCOPE.PLANNED} {...formProps} />
              </DetailHolder>
            )}
            <DetailHolder>
              <Text inline={false}>{t('consignments:table.columns.actual_qty')}</Text>
              <Quantities consignment={consignment} scope={SCOPE.ACTUAL} {...formProps} />
            </DetailHolder>
            <FlexedContainer>
              <DetailHolder>
                <Text inline={false}>{t('consignments:table.columns.total_parcel_qty')}</Text>
                <Input
                  label={t('consignments:quantities.placeholder.total_parcel_qty')}
                  placeholder={t('consignments:quantities.placeholder.total_parcel_qty')}
                  showLabel={false}
                  defaultValue={total_parcels_quantity || ''}
                  ref={formProps.register}
                  name="consignments:unit_quantities.total_parcels_quantity"
                  postfixText="PAK"
                  onChange={onInputChange}
                  disabled={isTotalParcelQtyDisabled}
                  autoComplete="off"
                  inputMode="numeric"
                  errors={i18n.t(formProps.errors?.['consignments:unit_quantities']?.total_parcels_quantity?.message)}
                />
              </DetailHolder>
              <DetailHolder>
                <Text inline={false}>{t(actualLabel)}</Text>
                <div>
                  <DateInput
                    value={actualTime}
                    disabled={allDisabled || !canUpdateTime}
                    onChange={setDateTime}
                    errors={i18n.t(formProps.errors?.['consignments:unit_quantities']?.[actualTimeProp]?.message)}
                  />
                  <input
                    type="hidden"
                    disabled={!canUpdateTime}
                    ref={formProps.register}
                    value={dateTime ? formatDatetime(dateTime) : actualTime ? formatDatetime(actualTime) : ''}
                    name={`consignments:unit_quantities.${actualTimeProp}`}
                  />
                </div>
              </DetailHolder>
            </FlexedContainer>
          </div>
          <div className="sidePanel__footer">
            {!isInbound && (
              <Button
                className="sidePanel__actionButton"
                disabled={allDisabled || dismissDisabled}
                inline
                type="button"
                onClick={() => setShowDismissConfirmation(true)}
              >
                {t('consignments:action.dismiss_consignment')}
              </Button>
            )}
            <Button className="sidePanel__actionButton" variant="primary" inline disabled={allDisabled}>
              {t('submit')}
            </Button>
          </div>
          {!showDismissConfirmation && loadingConsignment && <Loader />}
        </form>
      </StyledSidePanel>
      {showDismissConfirmation && (
        <StyledConfirmationBox
          headerTitle={t('consignments:header.ask_dismiss_consignment')}
          onClose={() => setShowDismissConfirmation(false)}
          icon={<DamageIcon />}
          submitText={t('dismiss')}
          onSubmit={onDismissConsignment}
          submitType="danger"
        >
          {t('consignments:content.dismiss_consignment_confirmation', { name: headerTitle })}
          {loadingConsignment && <Loader />}
        </StyledConfirmationBox>
      )}
    </ConsignmentContextProvider>
  );
}

Consignment.propTypes = {
  type: PropTypes.number.isRequired,
};
