import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sevensenders/component-library';

import useDateLocale from '../../../utils/hooks/useDateLocale';
import useTimeLocale from '../../../utils/hooks/useTimeLocale';

export default function DatetimeCell({ value }) {
  const dLocale = useDateLocale();
  const tLocale = useTimeLocale();

  if (!value) {
    return null;
  }

  const { from, until } = value;
  const start = new Date(from);
  const end = new Date(until);

  return (
    <>
      <Text inline={false}>{dLocale(start)}</Text>
      <Text secondary small inline={false}>
        {tLocale(start)} - {tLocale(end)}
      </Text>
    </>
  );
}

DatetimeCell.propTypes = {
  value: PropTypes.shape({
    from: PropTypes.string.isRequired,
    until: PropTypes.string.isRequired,
  }).isRequired,
};
