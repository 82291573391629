import React from 'react';
import styled from '@emotion/styled';
import ConfirmationBox from '../../components/ConfirmationBox';
import { ReactComponent as DamageSVG } from '../../icons/claims-damage.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions as popupActions } from '../../store/popup';

const DamageIcon = styled(DamageSVG)`
  color: var(--colors-dark-red);
  background: var(--colors-lighter-red);
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  margin-right: 16px;
`;

export default function Popup({ namespace }) {
  const popup = useSelector((state) => state.popup[namespace]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  if (popup) {
    return (
      <ConfirmationBox
        headerTitle={t(popup.title)}
        icon={<DamageIcon />}
        submitText={t('dismiss')}
        submitType="danger"
        onSubmit={() => {
          popup.submitCallback && popup.submitCallback();
          dispatch(popupActions.close(namespace));
        }}
        onClose={() => {
          popup.closeCallback && popup.closeCallback();
          dispatch(popupActions.close(namespace));
        }}
      >
        {t(popup.message)}
      </ConfirmationBox>
    );
  } else {
    return null;
  }
}
