import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { TYPES } from '../../../config/consignments';
import { ConsignmentContext } from '../../Consignments/context';
import DatetimeCell from './DatetimeCell';
import { actions as consignmentActions } from '../../../store/consignments';

const StyledLink = styled(Link)`
  text-decoration: none;
  span:first-of-type {
    color: var(--colors-sky-blue);
    position: relative;
    display: inline-block;
  }
`;

export default function ConsignmentLinkCell({ value, row: { original: data } }) {
  const { type } = useContext(ConsignmentContext);
  const dispatch = useDispatch();
  const isInbound = type === TYPES.INBOUND;
  const location = useLocation();

  const { id } = data;
  const pathname = `/consignments/${isInbound ? 'inbound' : 'outbound'}/${id}`;
  const goToConsignment = () => {
    dispatch(consignmentActions.setConsignmentById(id));
  };

  return (
    <StyledLink
      to={{
        pathname,
        state: { background: location },
      }}
      onClick={goToConsignment}
    >
      <DatetimeCell value={value} />
    </StyledLink>
  );
}

ConsignmentLinkCell.propTypes = {
  value: PropTypes.shape({
    from: PropTypes.string.isRequired,
    until: PropTypes.string.isRequired,
  }).isRequired,
  row: PropTypes.any.isRequired,
};
