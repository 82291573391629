import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button, H1 } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';

import config from '../../config';
import { actions as consignmentActions } from '../../store/consignments';
import { TYPES } from '../../config/consignments';
import { ReactComponent as DownloadIconSVG } from '../../icons/download-icon.svg';
import { ConsignmentContextProvider } from './context';

import PageHeader from '../../components/PageHeader';
import Filters from '../Filters';
import ConsignmentsTable from '../ConsignmentsTable';
import Alerts from '../Alerts';
import Popup from '../Popup';

const DownloadIconWrapper = styled.span`
  display: inline-flex;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`;

const HeaderButton = styled(Button)`
  margin-left: 8px;
  text-decoration: none;
`;

const Body = styled.div`
  height: calc(100vh - 62px - 48px);
  overflow: auto;
`;

function DownloadIcon({ loading }) {
  return (
    <DownloadIconWrapper>
      {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
      {!loading && <DownloadIconSVG />}
    </DownloadIconWrapper>
  );
}

DownloadIcon.propTypes = {
  loading: PropTypes.bool,
};

function Consignments(props) {
  const { type } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const requestCanceller = useRef();
  const [downloading, setDownloading] = useState(false);

  const downloadResults = () => {
    requestCanceller.current && requestCanceller.current.cancel();

    setDownloading(true);
    const [promise, canceller] = dispatch(consignmentActions.exportConsignments(type));
    requestCanceller.current = canceller;

    promise
      .catch(() => {
        console.log('Error while downloading csv');
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <ConsignmentContextProvider value={{ ...props }}>
      <PageHeader>
        <H1>{t(type === TYPES.OUTBOUND ? 'consignments:outbound' : 'consignments:inbound')}</H1>
        <div style={{ paddingTop: '35px' }}>
          <Alerts namespace="columnsConfig" />
        </div>
        <div style={{ display: 'flex' }}>
          <HeaderButton
            as="a"
            inline
            href={`mailto:${config.contact.email}?cc=${config.contact.emailCC}`}
            target="_blank"
          >
            {t('consignments:header.contact')}
          </HeaderButton>
          <HeaderButton
            variant="primary"
            leftIcon={<DownloadIcon loading={downloading} />}
            inline
            onClick={downloadResults}
          >
            {t('consignments:header.download')}
          </HeaderButton>
        </div>
      </PageHeader>
      <Body>
        <Filters />
        <ConsignmentsTable />
        <Popup namespace="columnsConfig" />
      </Body>
    </ConsignmentContextProvider>
  );
}

Consignments.propTypes = {
  type: PropTypes.number.isRequired,
};

export default React.memo(Consignments);
