import produce from 'immer';
import { types as actionTypes } from './actions';
import { actions as userActions } from '../user';

const reducersMap = {
  [actionTypes.SET_NOTIFICATION_SETTINGS]: (state, settings) => {
    state.notification = settings;
    state.loading = false;
    return state;
  },
  [actionTypes.SET_SETTINGS_LOADING]: (state, isLoading) => {
    state.loading = !!isLoading;
    return state;
  },
  [userActions.types.LOGOUT]: () => {
    return initialState;
  },
};

const initialState = {
  notification: null,
  loading: false,
};

export default (state = initialState, { type: actionType, payload }) =>
  produce(state, (draft) => (actionType in reducersMap && reducersMap[actionType](draft, payload)) || state);
