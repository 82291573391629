import { isCurrentUserLsp } from '../user';

export { getRowComponentByStatus } from './table';

export const isEditable = (consignment, quantitytype) => {
  // Check if the consignment can report quantity
  if (canReportQuantity(consignment)) {
    return true;
  }

  // Check if the planned quantity for the given quantity type is null
  const quantity = consignment.quantities[quantitytype];
  if (quantity && quantity.planned_quantity === null) {
    return true;
  }

  // Check if it is less than 16:00 Berlin time
  // if (isBeforeCutOffTime(consignment)) {
  //   return true;
  // }

  return false;
};

export const isClosed = (consignment) => consignment.status === 'closed';

export const isDismissed = (consignment) => {
  const { status, unit_quantities } = consignment;
  const isPlannedQuantityZero = Object.keys(unit_quantities).every(
    (key) => !isEditable(consignment, key) && unit_quantities[key].planned_quantity === 0
  );
  return isPlannedQuantityZero && status === 'clarify-confirmed';
};

export const isBlocked = (consignment) => isDismissed(consignment) || isClosed(consignment);

const validPlannedQuantity = (consignment) => {
  const { unit_quantities } = consignment;

  // Filter unit_quantities array to only keep objects with dispatch_unit_type 'PAL'
  const filteredUnitQuantities = unit_quantities.filter((unit) => unit.dispatch_unit_type.short_name === 'PAL');

  // Check if all filtered objects have planned_quantity as a valid integer
  return filteredUnitQuantities.every((unit) => Number.isInteger(unit.planned_quantity) && unit.planned_quantity >= 0);
};

export const canReportQuantity = (consignment) => consignment.can_report_quantity;

//export const isBeforeCutOffTime = (consignment) => consignment.before_cut_off_time;

/**
 * Check if consignment can report planned quantity
 * @param consignment
 * @param quantityType
 * @returns {boolean}
 */
export const canReportPlannedQuantity = (consignment, quantityType) => {
  return !isClosed(consignment) && !isDismissed(consignment) && isEditable(consignment, quantityType);
};

const validPickupQuantity = (consignment) => {
  const { unit_quantities } = consignment;
  return Object.keys(unit_quantities).some((key) => unit_quantities[key].picked_up_quantity > 0);
};

export const canReportPlannedQuantities = (consignment) => {
  return (
    consignment.quantities &&
    Object.keys(consignment.quantities).reduce((a, key) => a && canReportPlannedQuantity(consignment, key), true)
  );
};

export const canReportPickupQuantity = (consignment) => {
  if (!isBlocked(consignment) && !canReportQuantity(consignment)) {
    return true;
  }

  return !isBlocked(consignment) && validPlannedQuantity(consignment) && !validPickupQuantity(consignment);
};

const validDropOffQuantity = (consignment) => {
  const { unit_quantities } = consignment;
  return Object.keys(unit_quantities).some((key) => unit_quantities[key].dropped_off_quantity > 0);
};

const isMissingReported = (consignment) => {
  const { status, unit_quantities } = consignment;
  const isActualDroppedOffQuantityZero = Object.keys(unit_quantities).some(
    (key) => unit_quantities[key].dropped_off_quantity === 0
  );
  return isActualDroppedOffQuantityZero && status === 'delivery-unconfirmed';
};

export const canReportDropOffQuantity = (consignment) => {
  return (
    !isBlocked(consignment) &&
    !validDropOffQuantity(consignment) &&
    !isMissingReported(consignment) &&
    !isClosed(consignment)
  );
};

export const canUpdatePickupTime = (consignment) => {
  const { actual_pick_up_time } = consignment;

  if (!isBlocked(consignment) && actual_pick_up_time === null && !canReportQuantity(consignment)) {
    return true;
  }

  return !isBlocked(consignment) && validPlannedQuantity(consignment) && actual_pick_up_time === null;
};

export const canUpdateDropOffTime = (consignment) => {
  const { actual_drop_off_time } = consignment;
  return !isBlocked(consignment) && actual_drop_off_time === null && !isMissingReported(consignment);
};

export const canReportTotalParcelsQuantityOutbound = (consignment) => {
  const { total_parcels_quantity } = consignment;
  return !isBlocked(consignment) && (canReportPickupQuantity(consignment) || isNaN(String(total_parcels_quantity)));
};

export const canReportTotalParcelsQuantityInbound = (consignment) => {
  const { total_parcels_quantity, actual_drop_off_time } = consignment;
  const isDropOffAllowed = actual_drop_off_time !== null && validDropOffQuantity(consignment);
  return (
    !isBlocked(consignment) &&
    isNaN(String(total_parcels_quantity)) &&
    (isDropOffAllowed || isMissingReported(consignment))
  );
};

export const getNumberValue = (value) => {
  const newValue = Number.parseInt(value, 10);
  return Number.isNaN(newValue) ? '' : newValue;
};

export const canDismissConsignment = (consignment) => {
  return canReportPlannedQuantities(consignment) && !validPickupQuantity(consignment);
};

export const allInputDisabledOutbound = (consignment) => {
  if (isBlocked(consignment)) {
    return true;
  }

  if (
    canReportTotalParcelsQuantityOutbound(consignment) ||
    canReportPickupQuantity(consignment) ||
    canReportPlannedQuantities(consignment) ||
    canUpdatePickupTime(consignment)
  ) {
    return false;
  }

  return !canReportQuantity(consignment);
};

export const allInputDisabledInbound = (consignment) => {
  return (
    isBlocked(consignment) ||
    (!canUpdateDropOffTime(consignment) &&
      !canReportDropOffQuantity(consignment) &&
      !canReportTotalParcelsQuantityInbound(consignment))
  );
};

export const isLsp = () => isCurrentUserLsp();
