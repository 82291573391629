import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { de as DateLocaleDE } from 'date-fns/locale';
import { DatePickerUtil } from '@sevensenders/component-library';

import en from './en';
import de from './de';

// setup react-hook-form validations locale
import './validations';

const resources = {
  en,
  de,
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: Object.keys(resources),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupLocalStorage: 'lang',
    },
  });

DatePickerUtil.addLocales({ de: DateLocaleDE });

export default i18next;
