import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { H1 } from '@sevensenders/component-library';

import PageContainer from '../PageContainer';
import './page-container.scss';
import { useTranslation } from 'react-i18next';

export default function TwoColumnPageContainer({ children, routes, title }) {
  const { t } = useTranslation();

  const submenus = useMemo(
    () =>
      routes.map(({ path, title }, key) => (
        <NavLink exact to={path} className="TwoColumn__SubNavLink" key={key} activeClassName="active">
          {t(title)}
        </NavLink>
      )),
    [t, routes]
  );

  return (
    <PageContainer className="TwoColumn">
      <nav className="TwoColumn__SubNav" aria-label={t('nav.submenu_label')}>
        <H1 className="TwoColumn__SubNavHeader">{t(title)}</H1>
        {submenus}
      </nav>
      <section>{children}</section>
    </PageContainer>
  );
}

TwoColumnPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
