import API, { createCancelToken } from '../../api';
import storage from '../../utils/storage';
import { actions as loaderActions } from '../loader';
import { actions as alertActions } from '../alerts';
import { actions as consignmentActions } from '../consignments';

export const types = {
  LOGOUT: 'LOGOUT',
  SET_TOKEN: 'SET_TOKEN',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
};

/**
 * token regenerate events
 */
let listeningAPIEvents = false;

const onRegenerateTokenSuccess =
  (dispatch) =>
  ({ detail: data }) => {
    const { token, refresh_token } = data;
    const rememberMe = storage.get('rememberMe') === 'true';

    dispatch(setToken(token, rememberMe && refresh_token));
  };

const onRegenerateTokenFailure = (dispatch) => () => {
  dispatch(logout());
};

/**
 * Actions
 */

const setToken = (token, refreshToken) => {
  storage.set('token', token);
  refreshToken && storage.set('refreshToken', refreshToken);

  API.setToken(token, refreshToken);

  return {
    type: types.SET_TOKEN,
    payload: { token, refreshToken },
  };
};

export const setDetails = (data) => ({
  type: types.SET_USER_DETAILS,
  payload: data,
});

export const init = () => (dispatch) => {
  const token = storage.get('token');
  const refreshToken = storage.get('refreshToken');
  const rememberMe = storage.get('rememberMe') === 'true';

  if (!listeningAPIEvents) {
    API.listen('regenerate_success', onRegenerateTokenSuccess(dispatch));
    API.listen('regenerate_failure', onRegenerateTokenFailure(dispatch));
    listeningAPIEvents = true;
  }

  if (!token || (rememberMe && !refreshToken)) {
    storage.clear();
    return;
  }

  dispatch(setToken(token, rememberMe && refreshToken));
  dispatch(consignmentActions.fetchFilters());

  return dispatch(userDetails());
};

export const login = (username, password, rememberMe) => (dispatch) => {
  let canceller = createCancelToken();

  storage.set('rememberMe', rememberMe);

  dispatch(alertActions.clear('login'));
  dispatch(loaderActions.setLoading(true));
  API.login(username, password, canceller)
    .then(({ data }) => {
      const { token, refresh_token } = data;
      dispatch(setToken(token, rememberMe && refresh_token));
      dispatch(consignmentActions.fetchFilters());
      dispatch(userDetails(canceller));
    })
    .catch(({ response }) => {
      const message = response?.data?.message || response?.data?.error || 'server:error.500';
      dispatch(alertActions.add('login', 'error', message));
    })
    .finally(() => {
      dispatch(loaderActions.setLoading(false));
    });

  return canceller;
};

export const forgotPassword = (username) => (dispatch) => {
  let canceller = createCancelToken();

  dispatch(loaderActions.setLoading(true));
  const promise = API.forgotPassword(username, canceller)
    .catch(({ response }) => {
      const message = response?.data?.message || response?.data?.error || 'server:error.500';
      dispatch(alertActions.add('forgot-password', 'error', message));
    })
    .finally(() => {
      dispatch(loaderActions.setLoading(false));
    });

  return [canceller, promise];
};

export const confirmPassword = (username, code, password) => (dispatch) => {
  let canceller = createCancelToken();

  dispatch(loaderActions.setLoading(true));
  const promise = API.confirmPassword(username, code, password, canceller)
    .then((data) => {
      dispatch(alertActions.add('login', 'success', 'password_updated_message'));
      return data;
    })
    .catch(({ response }) => {
      const message = response?.data?.message || response?.data?.error || 'server:error.500';
      dispatch(alertActions.add('confirm-password', 'error', message));
    })
    .finally(() => {
      dispatch(loaderActions.setLoading(false));
    });

  return [canceller, promise];
};

export const logout = () => (dispatch) => {
  storage.clear();
  dispatch({ type: types.LOGOUT });
};

export const userDetails =
  (cancelToken = null) =>
  (dispatch) => {
    const canceller = cancelToken || createCancelToken();

    dispatch(loaderActions.setLoading(true));

    API.userDetails(canceller)
      .then(({ data }) => {
        const { roles } = data;
        storage.set('roles', roles);
        if (!roles || roles.length === 0) {
          throw new Error("User doesn't have roles");
        }
        dispatch(setDetails(data));
      })
      .catch((error) => {
        dispatch(logout());
      })
      .finally(() => {
        canceller.cancel();
        dispatch(loaderActions.setLoading(false));
      });

    return canceller;
  };
