import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import * as DateFNSLocaleUtils from '../../locales/date-util';

/**
 * Returns translator function for date and formats it under locale
 * @returns {function(*=): string | *}
 */
export default function useTimeLocale() {
  const {
    i18n: { language },
  } = useTranslation();
  const locale = DateFNSLocaleUtils.getLocale(language);
  const timeFormat = useMemo(() => locale.formatLong.time({ width: 'short' }), [locale]);

  return useCallback((date) => format(date, timeFormat, { locale }), [timeFormat, locale]);
}
