import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import './locales';

import '@sevensenders/component-library/styles/fonts.scss';
import './index.scss';
import App from './App';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '287b9099-b66f-45d1-ad36-3d31d0fa827d',
    clientToken: 'pub7b84191fcaf2d054fc3f1f8971f5dd7c',
    site: 'datadoghq.eu',
    service: 'dat-partner-interface',
    env: 'production',
    sessionSampleRate: 0,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
