import React from 'react';
import PropTypes from 'prop-types';

export const ConsignmentContext = React.createContext({});

export const ConsignmentContextProvider = ({ value, children }) => {
  return <ConsignmentContext.Provider value={value}>{children}</ConsignmentContext.Provider>;
};

ConsignmentContextProvider.propTypes = {
  value: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
};
