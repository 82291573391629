import produce from 'immer';
import { types as actionTypes } from './actions';

const addAlert = (state, { namespace, type, message }) => {
  if (namespace in state) {
    state[namespace].push({ type, message });
  } else {
    state[namespace] = [{ type, message }];
  }
  return state;
};

const removeAlert = (state, { namespace, index }) => {
  if (namespace in state) {
    state[namespace].splice(index, 1);
  }
  return state;
};

const clearAlerts = (state, { namespace }) => {
  if (namespace in state) {
    delete state[namespace];
    state[namespace] = [];
  }
  return state;
};

const reducersMap = {
  [actionTypes.ADD_ALERT]: addAlert,
  [actionTypes.REMOVE_ALERT]: removeAlert,
  [actionTypes.CLEAR_ALERTS]: clearAlerts,
};

const initialState = {
  transport: [],
};

export default (state = initialState, { type: actionType, payload }) =>
  produce(state, (draft) => (actionType in reducersMap && reducersMap[actionType](draft, payload)) || state);
