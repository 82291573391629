import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PageContainer from '../containers/PageContainer';
import { actions as userActions } from '../store/user';
import TwoColumnPageContainer from '../containers/TwoColumnPageContainer';
import AuthenticatedRoute from './AuthenticatedRoute';
import ScrollToTop from './ScrollToTop';

import {
  LoginPage,
  NotFoundPage,
  OutboundConsignments,
  OutboundConsignment,
  InboundConsignments,
  InboundConsignment,
  ForgotPasswordPage,
  UserSettings,
  NotificationSettings,
} from '../pages';

const loginPath = '/login';
const indexPath = '/consignments/outbound';

function Logout() {
  const dispatch = useDispatch();
  dispatch(userActions.logout());
  return <Redirect to={{ pathname: loginPath }} />;
}

const settingsSubMenu = [
  {
    path: '/settings/notifications',
    title: 'sub_settings.notifications',
  },
  {
    path: '/settings/user',
    title: 'sub_settings.user',
  },
];

function AppRoutes() {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.user.logged);
  const authProps = {
    isAuthenticated,
    loginPath,
    indexPath,
  };

  const background = location.state && location.state.background;

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Redirect exact from="/" to={{ pathname: isAuthenticated ? indexPath : loginPath }} />
        <Redirect exact from="/consignments" to={{ pathname: isAuthenticated ? indexPath : loginPath }} />
        {/* Removes duplicate slashes */}
        <Route
          exact
          strict
          path="(.*//+.*)"
          render={({ location }) => <Redirect to={location.pathname.replace(/\/\/+/g, '/')} />}
        />
        {/* Removes trailing slashes */}
        <Route
          exact
          strict
          path="(.*/)"
          render={({ location }) => <Redirect to={location.pathname.replace(/\/$/g, '')} />}
        />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <AuthenticatedRoute {...authProps} path="/consignments">
          <PageContainer>
            <Switch location={background || location}>
              <Route exact path="/consignments/outbound" component={OutboundConsignments} />
              <Route exact path="/consignments/inbound" component={InboundConsignments} />
            </Switch>
            <Route exact path="/consignments/outbound/:id" component={OutboundConsignment} />
            <Route exact path="/consignments/inbound/:id" component={InboundConsignment} />
          </PageContainer>
        </AuthenticatedRoute>
        <AuthenticatedRoute {...authProps} path="/settings">
          <TwoColumnPageContainer title="settings" routes={settingsSubMenu}>
            <Route exact strict path="/settings" render={() => <Redirect to="/settings/notifications" />} />
            <Route exact path="/settings/notifications" component={NotificationSettings} />
            <Route exact path="/settings/user" component={UserSettings} />
          </TwoColumnPageContainer>
        </AuthenticatedRoute>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default React.memo(AppRoutes);
