import React from 'react';
import { H1, Text, Button } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Image404 } from './404.svg';

export default function NotFound() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div style={{ textAlign: 'center' }}>
      <H1>{t('not_found.title')}</H1>
      <Text>{t('not_found.subtext')}</Text>
      <div style={{ marginBottom: '30px' }}>
        <Image404 />
      </div>
      <Button onClick={() => history.push('/')} variant="primary" inline large>
        {t('not_found.return_button')}
      </Button>
    </div>
  );
}
