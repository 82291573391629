import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sevensenders/component-library';

export default function DefaultCell({ value }) {
  return <Text inline={false}>{value}</Text>;
}

DefaultCell.propTypes = {
  value: PropTypes.string,
};
