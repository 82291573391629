import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Text, useOutsideClick } from '@sevensenders/component-library';

import Overlay from '../Overlay';
import { getScroll } from '../../utils/dom';

const EditableText = styled(Text)`
  position: relative;
  z-index: 1;

  ${({ open }) =>
    open &&
    `
    color: var(--colors-filter-blue);
  `};

  ${({ editable, open }) =>
    editable &&
    `
    display: inline;
    outline: none;

    &:focus::after {
      box-shadow: 0px 0px 5px var(--colors-sky-blue);
    }
    
    &::after {
      position: absolute;
      content: ' ';
      height: 32px;
      min-width: calc(100% + 16px);
      background: ${open ? 'var(--colors-lightest-sky-blue-two)' : 'var(--colors-smoke)'};
      top: -8px;
      left: -8px;
      border-radius: 4px;
      z-index: -1;
    }
  `};
`;

const SubText = styled(Text)`
  ${({ editable }) =>
    editable &&
    `
    display: block;
  `};
`;

const ContentWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: ${({ top }) => (top ? top : 'auto')};
  right: ${({ right }) => (right ? right : 'auto')};
`;

const EditableCell = React.forwardRef(function EditableCellWithRef(
  { className, subText, value, editable, children, canClose },
  cellRef
) {
  const contentRef = useRef();
  const dataRef = useRef();
  const [showForm, setShowForm] = useState(false);
  const [contentPosition, setContentPosition] = useState({ top: 'auto', right: 'auto' });

  const onKeyPress = useCallback(
    (onClick) => (e) => {
      const enterKey = 13;
      const spaceKey = 32;
      const { charCode: keyCode } = e;

      if (keyCode === enterKey || keyCode === spaceKey) {
        onClick(e);
        e.preventDefault();
        e.stopPropagation();
      }
    },
    []
  );

  const onEditClick = useCallback(() => {
    if (!editable) return;

    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = dataRef.current;
    const { left: scrollLeft, top: scrollTop } = getScroll(dataRef.current);
    setContentPosition({
      top: `${offsetTop + offsetHeight + 12 - scrollTop}px`,
      right: `calc(100% - ${offsetLeft + offsetWidth + 8 - scrollLeft}px)`,
    });

    setShowForm(!showForm);
  }, [editable, showForm, setShowForm, setContentPosition, dataRef]);

  const onClose = useCallback(() => showForm && setShowForm(false), [showForm, setShowForm]);

  useOutsideClick(contentRef, (e) => {
    if (canClose) {
      canClose(contentRef, e) && onClose(e);
    } else {
      onClose(e);
    }
  });

  const editableProps = {};
  if (editable) {
    editableProps.tabIndex = 0;
  }

  // Close listener
  useEffect(() => {
    const elem = cellRef && cellRef.current;
    elem && elem.addEventListener('close', onClose);
    return () => elem && elem.removeEventListener('close', onClose);
  }, [cellRef, onClose]);

  return (
    <div ref={cellRef} className={className}>
      <EditableText
        ref={dataRef}
        open={showForm}
        editable={editable}
        inline={false}
        {...editableProps}
        onClick={onEditClick}
        onKeyPress={onKeyPress(onEditClick)}
      >
        {value}
      </EditableText>
      {subText && (
        <SubText editable={editable} inline={false} secondary small>
          {subText}
        </SubText>
      )}
      {showForm && (
        <>
          <Overlay />
          <ContentWrapper ref={contentRef} {...contentPosition}>
            {children}
          </ContentWrapper>
        </>
      )}
    </div>
  );
});

EditableCell.propTypes = {
  className: PropTypes.string,
  subText: PropTypes.string,
  value: PropTypes.any,
  editable: PropTypes.bool,
  children: PropTypes.node,
  canClose: PropTypes.func,
};

export default EditableCell;
