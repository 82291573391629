import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { H2, H3, H4, Button, Text, Select, Toggle, useEffectOnce, Loader } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { ucFirst } from '../../utils/string';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import { actions as settingActions } from '../../store/settings';

const SubSection = styled.div`
  &::before {
    content: '';
    display: block;
    height: 1px;
    background: var(--colors-isabeline);
    margin: 24px 0;
  }

  h4 {
    margin: 0;
  }
`;

const Form = styled.form`
  position: relative;

  .reminder__OptionsHolder,
  .marginTop_16 {
    margin-top: 16px;
  }
`;

const SettingsLoader = styled(Loader)`
  left: -32px;
  top: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 24px);
  max-width: calc(100% + 48px);
`;

const FlexedContainer = styled.div`
  display: flex;
  margin-bottom: 24px;

  > * {
    flex: 0.5;
    margin-right: 32px;

    &:last-of-type {
      margin: 0;
    }

    &:first-of-type {
      margin-right: 32px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const daysBeforeTimeOptions = Array(24)
  .fill(null)
  .map((_, i) => ({
    label: `${`${i + 1}`.padStart(2, '0')}:00`,
    value: i + 1,
  }));

const numberOfReminderOptions = Array(5)
  .fill(null)
  .map((_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

const resolveTimeIntervalTranslation = (time) => {
  if (time === 24) return 'every_day';
  return time === 1 ? 'every_hour' : 'every_hours';
};

const getIntervalOptions = (t) =>
  [1, 2, 3, 24].map((value) => ({
    label: t(`notifications.section_email.planned_data.interval_options.${resolveTimeIntervalTranslation(value)}`, {
      value,
    }),
    value,
  }));

const resolveDayBeforeTranslation = (day) => {
  return day === 1 ? 'day_before' : 'days_before';
};

const getDaysBeforeOptions = (t) =>
  Array(7)
    .fill(null)
    .map((_, i) => ({
      label: t(`notifications.section_email.planned_data.days_before_options.${resolveDayBeforeTranslation(i + 1)}`, {
        value: i + 1,
      }),
      value: i + 1,
    }));

const resolveHourAfterTranslation = (day) => {
  return day === 1 ? 'after_hour' : 'after_hours';
};

const getAfterHourOptions = (t) =>
  Array(10)
    .fill(null)
    .map((_, i) => ({
      label: t(`notifications.section_email.actual_data.hours_after_options.${resolveHourAfterTranslation(i + 1)}`, {
        value: i + 1,
      }),
      value: i + 1,
    }));

const BEFORE_AFTER_DISABLED = true;

const schema = yup.object().shape({
  additional_reminder_notification: yup.object({
    planned: yup.object(),
    actual: yup.object(),
  }),
  missing_forecast_notification_interval_day: yup.mixed(),
  missing_forecast_notification_time: yup.mixed(),
  missing_pickup_notification_interval_hour: yup.mixed(),
});

const findOptionByValue = (options, value) => options.find((obj) => obj.value === value) || ' ';

const getPropsWithoutRef = (props) => {
  const { ref, ...otherProps } = props;
  return otherProps;
};

export default function NotificationSettings() {
  const canceller = useRef();
  const [canSendPlannedReminder, setCanSendPlannedReminder] = useState(false);
  const [canSendActualReminder, setCanSendActualReminder] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notification, loading } = useSelector((state) => state.settings);
  const isNotificationDisabled = !!!notification;

  const daysBeforeOptions = useMemo(() => getDaysBeforeOptions(t), [t]);
  const intervalOptions = useMemo(() => getIntervalOptions(t), [t]);
  const hoursAfterOptions = useMemo(() => getAfterHourOptions(t), [t]);

  const defaultValues = useMemo(
    () => ({
      missing_forecast_notification_interval_day: findOptionByValue(
        daysBeforeOptions,
        notification?.missing_forecast_notification_interval_day
      ),
      missing_forecast_notification_time: findOptionByValue(
        daysBeforeTimeOptions,
        notification?.missing_forecast_notification_time
      ),
      missing_pickup_notification_interval_hour: findOptionByValue(
        hoursAfterOptions,
        notification?.missing_pickup_notification_interval_hour
      ),
      additional_reminder_notification: {
        planned: {
          is_active: notification?.additional_reminder_notification?.planned?.is_active,
          number_of_reminders: findOptionByValue(
            numberOfReminderOptions,
            notification?.additional_reminder_notification?.planned?.number_of_reminders
          ),
          interval: findOptionByValue(
            intervalOptions,
            notification?.additional_reminder_notification?.planned?.interval
          ),
        },
        actual: {
          is_active: notification?.additional_reminder_notification?.actual?.is_active,
          number_of_reminders: findOptionByValue(
            numberOfReminderOptions,
            notification?.additional_reminder_notification?.actual?.number_of_reminders
          ),
          interval: findOptionByValue(
            intervalOptions,
            notification?.additional_reminder_notification?.actual?.interval
          ),
        },
      },
    }),
    [notification, daysBeforeOptions, hoursAfterOptions, intervalOptions]
  );

  const { register, control, handleSubmit, setValue } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  // mount
  useEffectOnce(() => {
    canceller.current && canceller.current.cancel();
    canceller.current = dispatch(settingActions.getCustomerSettings());
  });

  useEffect(() => {
    setCanSendPlannedReminder(notification?.additional_reminder_notification?.planned?.is_active || false);
    setCanSendActualReminder(notification?.additional_reminder_notification?.actual?.is_active || false);

    setValue(
      'additional_reminder_notification[planned][is_active]',
      notification?.additional_reminder_notification?.planned?.is_active
    );
    setValue(
      'additional_reminder_notification[planned][number_of_reminders]',
      findOptionByValue(
        numberOfReminderOptions,
        notification?.additional_reminder_notification?.planned?.number_of_reminders
      )
    );
    setValue(
      'additional_reminder_notification[planned][interval]',
      findOptionByValue(intervalOptions, notification?.additional_reminder_notification?.planned?.interval)
    );

    setValue(
      'additional_reminder_notification[actual][is_active]',
      notification?.additional_reminder_notification?.actual?.is_active
    );
    setValue(
      'additional_reminder_notification[actual][number_of_reminders]',
      findOptionByValue(
        numberOfReminderOptions,
        notification?.additional_reminder_notification?.actual?.number_of_reminders
      )
    );
    setValue(
      'additional_reminder_notification[actual][interval]',
      findOptionByValue(intervalOptions, notification?.additional_reminder_notification?.actual?.interval)
    );
  }, [notification, setCanSendPlannedReminder, setCanSendActualReminder, setValue, intervalOptions]);

  // unmount
  useEffect(
    () => () => {
      canceller.current && canceller.current.cancel();
    },
    [canceller]
  );

  const setCheckedState = (setter) => (e) => {
    setter(e.target.checked);
  };

  const onSave = useCallback(
    (data) => {
      data.additional_reminder_notification.planned.number_of_reminders =
        data.additional_reminder_notification.planned.number_of_reminders.value || 0;
      data.additional_reminder_notification.planned.interval =
        data.additional_reminder_notification.planned.interval.value || 0;
      data.additional_reminder_notification.actual.number_of_reminders =
        data.additional_reminder_notification.actual.number_of_reminders.value || 0;
      data.additional_reminder_notification.actual.interval =
        data.additional_reminder_notification.actual.interval.value || 0;

      // cleanup payload
      delete data.missing_forecast_notification_interval_day;
      delete data.missing_forecast_notification_time;
      delete data.missing_pickup_notification_interval_hour;

      dispatch(settingActions.updateCustomerSettings(data));
    },
    [dispatch]
  );

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <PageHeader>
        <H2>{t('sub_settings.notifications')}</H2>
        <Button variant="primary" inline disabled={isNotificationDisabled}>
          {t('action.save_settings')}
        </Button>
      </PageHeader>
      <Card>
        <H3>{t('notifications.section_email.title')}</H3>
        <Text className="Card__subText" secondary>
          {t('notifications.section_email.subtext')}
        </Text>
        <SubSection>
          <H4>{t('notifications.section_email.planned_data.title')}</H4>
          <Text className="Card__subText" secondary>
            {t('notifications.section_email.planned_data.subtext')}
          </Text>
          <FlexedContainer>
            <Controller
              name="missing_forecast_notification_interval_day"
              control={control}
              defaultValue={defaultValues.missing_forecast_notification_interval_day}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.missing_forecast_notification_interval_day}
                  options={daysBeforeOptions}
                  label={t('notifications.section_email.planned_data.days_before')}
                  inline
                  isDisabled={BEFORE_AFTER_DISABLED || isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
            <Controller
              name="missing_forecast_notification_time"
              control={control}
              defaultValue={defaultValues.missing_forecast_notification_time}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.missing_forecast_notification_time}
                  options={daysBeforeTimeOptions}
                  label={ucFirst(t('at'))}
                  inline
                  isDisabled={BEFORE_AFTER_DISABLED || isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
          </FlexedContainer>
          <Toggle
            name="additional_reminder_notification[planned][is_active]"
            label={t('notifications.section_email.reminder_button')}
            ref={register}
            disabled={isNotificationDisabled}
            onChange={setCheckedState(setCanSendPlannedReminder)}
            checked={canSendPlannedReminder}
          />
          <FlexedContainer
            className="reminder__OptionsHolder"
            style={{ display: canSendPlannedReminder ? 'flex' : 'none' }}
          >
            <Controller
              name="additional_reminder_notification[planned][number_of_reminders]"
              control={control}
              defaultValue={defaultValues.additional_reminder_notification.planned.number_of_reminders}
              value={defaultValues.additional_reminder_notification.planned.number_of_reminders}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.additional_reminder_notification.planned.number_of_reminders}
                  options={numberOfReminderOptions}
                  label={t('notifications.section_email.number_of_reminders')}
                  inline
                  isDisabled={isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
            <Controller
              name="additional_reminder_notification[planned][interval]"
              control={control}
              defaultValue={defaultValues.additional_reminder_notification.planned.interval}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.additional_reminder_notification.planned.interval}
                  options={intervalOptions}
                  label={t('notifications.section_email.interval')}
                  inline
                  isDisabled={isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
          </FlexedContainer>
          {canSendPlannedReminder &&
            defaultValues.additional_reminder_notification.planned.number_of_reminders.value &&
            defaultValues.additional_reminder_notification.planned.interval.label && (
              <Text className="marginTop_16" secondary inline={false}>
                {t('notifications.section_email.planned_data.reminder_hint', {
                  count: defaultValues.additional_reminder_notification.planned.number_of_reminders.value,
                  interval: defaultValues.additional_reminder_notification.planned.interval.label.toLowerCase(),
                })}
              </Text>
            )}
        </SubSection>
        <SubSection>
          <H4>{t('notifications.section_email.actual_data.title')}</H4>
          <Text className="Card__subText" secondary>
            {t('notifications.section_email.actual_data.subtext')}
          </Text>
          <FlexedContainer>
            <Controller
              name="missing_pickup_notification_interval_hour"
              control={control}
              defaultValue={defaultValues.missing_pickup_notification_interval_hour}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.missing_pickup_notification_interval_hour}
                  options={hoursAfterOptions}
                  label={t('notifications.section_email.actual_data.hours_after')}
                  inline
                  isDisabled={BEFORE_AFTER_DISABLED || isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
          </FlexedContainer>
          <Toggle
            name="additional_reminder_notification[actual][is_active]"
            label={t('notifications.section_email.reminder_button')}
            ref={register}
            disabled={isNotificationDisabled}
            onChange={setCheckedState(setCanSendActualReminder)}
            checked={canSendActualReminder}
          />
          <FlexedContainer
            className="reminder__OptionsHolder"
            style={{ display: canSendActualReminder ? 'flex' : 'none' }}
          >
            <Controller
              name="additional_reminder_notification[actual][number_of_reminders]"
              control={control}
              defaultValue={defaultValues.additional_reminder_notification.actual.number_of_reminders}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.additional_reminder_notification.actual.number_of_reminders}
                  options={numberOfReminderOptions}
                  label={t('notifications.section_email.number_of_reminders')}
                  inline
                  isDisabled={isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
            <Controller
              name="additional_reminder_notification[actual][interval]"
              control={control}
              defaultValue={defaultValues.additional_reminder_notification.actual.interval}
              render={(opts) => (
                <Select
                  {...getPropsWithoutRef(opts)}
                  value={defaultValues.additional_reminder_notification.actual.interval}
                  options={intervalOptions}
                  label={t('notifications.section_email.interval')}
                  inline
                  isDisabled={isNotificationDisabled}
                  isSearchable={false}
                />
              )}
            />
          </FlexedContainer>
          {canSendActualReminder &&
            defaultValues.additional_reminder_notification.actual.number_of_reminders.value &&
            defaultValues.additional_reminder_notification.actual.interval.label && (
              <Text className="marginTop_16" secondary inline={false}>
                {t('notifications.section_email.actual_data.reminder_hint', {
                  count: defaultValues.additional_reminder_notification.actual.number_of_reminders.value,
                  interval: defaultValues.additional_reminder_notification.actual.interval.label.toLowerCase(),
                })}
              </Text>
            )}
        </SubSection>
      </Card>
      {loading && <SettingsLoader />}
    </Form>
  );
}
