import { CancelToken } from 'axios';
import DAT from './DAT';
import config from '../config';

export default new DAT({
  baseURL: config.baseURL,
});

export function createCancelToken() {
  return CancelToken.source();
}
