/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: '$t(${path}) $t(validations:mixed.required)',
    notType: ({ path, type }) => {
      return `$t(${path}) $t(validations:is_not) $t(${type})`;
    },
  },
  number: {
    positive: '$t(${path}) $t(validations:types.number.positive)',
  },
});
