import DateRangePickerFilter from '../containers/Filters/types/DateRangePickerFilter';
import DateRangeValue from '../containers/Filters/values/DateRangeValue';
import PickupStationFilter from '../containers/Filters/types/PickupStationFilter';
import SelectValue from '../containers/Filters/values/SelectValue';
import LMCFilter from '../containers/Filters/types/LMCFilter';

export const FILTER_TYPES = Object.freeze({
  TEXT_INPUT: 0,
  DATE_RANGE: 1,
});

export const FILTERS = {
  plannedPickUpTime: {
    label: 'consignments:filter.options.planned_pickup',
    component: DateRangePickerFilter,
    valueComponent: DateRangeValue,
    width: '328px',
    denormalize: ({ from, to }) => ({ after: from, before: to }),
    normalize: ({ after, before }) => ({ from: after, to: before }),
    removable: false,
  },
  outboundPickupStation: {
    label: 'consignments:filter.options.from',
    component: PickupStationFilter,
    valueComponent: SelectValue,
    direction: 'outbound',
    width: '328px',
    removable: true,
  },
  outboundLMC: {
    label: 'consignments:filter.options.LMC',
    component: LMCFilter,
    valueComponent: SelectValue,
    direction: 'outbound',
    width: '328px',
    removable: true,
  },
  plannedDropOffTime: {
    label: 'consignments:filter.options.planned_drop_off',
    component: DateRangePickerFilter,
    valueComponent: DateRangeValue,
    width: '328px',
    denormalize: ({ from, to }) => ({ after: from, before: to }),
    normalize: ({ after, before }) => ({ from: after, to: before }),
    removable: false,
  },
  inboundPickupStation: {
    label: 'consignments:filter.options.from',
    component: PickupStationFilter,
    valueComponent: SelectValue,
    direction: 'inbound',
    width: '328px',
    removable: true,
  },
  inboundLMC: {
    label: 'consignments:filter.options.LMC',
    component: LMCFilter,
    valueComponent: SelectValue,
    direction: 'inbound',
    width: '328px',
    removable: true,
  },
};

export const OUTBOUND_FILTERS = {
  plannedPickUpTime: FILTERS.plannedPickUpTime,
  outboundPickupStation: FILTERS.outboundPickupStation,
  outboundLMC: FILTERS.outboundLMC,
};

export const INBOUND_FILTERS = {
  plannedDropOffTime: FILTERS.plannedDropOffTime,
  inboundPickupStation: FILTERS.inboundPickupStation,
  inboundLMC: FILTERS.inboundLMC,
};

export const OUTBOUND_FILTER_OPTIONS = Object.keys(OUTBOUND_FILTERS).map((filterName) => ({
  name: filterName,
  ...OUTBOUND_FILTERS[filterName],
}));

export const INBOUND_FILTER_OPTIONS = Object.keys(INBOUND_FILTERS).map((filterName) => ({
  name: filterName,
  ...INBOUND_FILTERS[filterName],
}));
