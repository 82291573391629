import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * check if user is authenticated and last pathname
 * @returns {[boolean, string]} Returns [isUserLoggedIn, referrerPathname]
 */
export default function useAuthCheck() {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.user.logged);

  let { pathname } = location.state?.from || { pathname: '/' };

  return [isLoggedIn, pathname];
}
