import styled from '@emotion/styled';

const PageHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '22px',
  justifyContent: 'space-between',
});

export default PageHeader;
