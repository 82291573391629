import * as React from 'react';
import PropTypes from 'prop-types';
import { H1, Loader, LogoIcon } from '@sevensenders/component-library';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import './login-container.scss';

function LoginContainer({ className, title, children }) {
  const isLoading = useSelector((state) => state.loader.loading);
  const classes = classnames('login-form', className);

  return (
    <div className="login-page">
      <div className="banner">
        <LogoIcon size={56} />
      </div>
      <main className={classnames({ loading: isLoading })}>
        <div className={classes}>
          <H1>{title}</H1>
          {children}
          {isLoading && <Loader />}
        </div>
      </main>
    </div>
  );
}

LoginContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(LoginContainer);
