import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Button, Card, Filter } from '@sevensenders/component-library';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { INBOUND_FILTER_OPTIONS, OUTBOUND_FILTER_OPTIONS } from '../../config/filters';
import { FilterTypeList } from './filter-type-list';
import { actions as consignmentActions } from '../../store/consignments';
import { TYPES } from '../../config/consignments';
import { ConsignmentContext } from '../Consignments/context';

const AddButton = styled(Button)`
  height: 31px;
  margin-bottom: 8px;
`;

const FilterCard = styled(Card)`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const FilterItem = styled(Filter)`
  margin-right: 8px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-right: 13px;
  }

  > .filterContainer {
    min-width: 186px;
    width: ${({ containerWidth }) => containerWidth ?? 'auto'};
    max-width: ${({ containerWidth }) => containerWidth ?? '100%'};
  }
`;

export default function Filters() {
  const { filters: savedFilters } = useSelector((state) => state.consignments);
  const [filters, setFilters] = useState([{}]);
  const { t } = useTranslation();
  const { type } = useContext(ConsignmentContext);
  const dispatch = useDispatch();

  const isOutbound = type === TYPES.OUTBOUND;
  const typeFilters = isOutbound ? savedFilters.outbound : savedFilters.inbound;

  const availableFilters = useMemo(
    () =>
      (isOutbound ? OUTBOUND_FILTER_OPTIONS : INBOUND_FILTER_OPTIONS).map((filter) => ({
        ...filter,
        label: t(filter.label),
      })),
    [t, isOutbound]
  );

  useEffect(() => {
    const newFilters = [];
    availableFilters.forEach((availableFilter) => {
      const { name } = availableFilter;
      if (name in typeFilters) {
        newFilters.push({ ...availableFilter, value: typeFilters[name] });
      }
    });
    setFilters(newFilters);
  }, [isOutbound, availableFilters, setFilters, typeFilters]);

  const addFilter = useMemo(
    () => (isOutbound ? consignmentActions.addOutboundFilter : consignmentActions.addInboundFilter),
    [isOutbound]
  );
  const removeFilter = useMemo(
    () => (isOutbound ? consignmentActions.removeOutboundFilter : consignmentActions.removeInboundFilter),
    [isOutbound]
  );

  const onRemoveFilter = (index, name) => () => {
    const newFilters = [...filters];

    newFilters.splice(index, 1);
    setFilters(newFilters);

    name && dispatch(removeFilter(name));
  };

  const onFilterTypeChange = (index, name) => (selectedOption) => {
    const newFilters = [...filters];
    newFilters[index] = { ...selectedOption };
    setFilters(newFilters);
  };

  const onFilterValueChange = (index, name) => (value) => {
    dispatch(addFilter({ name, value }));
  };

  const usedFilters = useMemo(() => filters.map(({ name }) => name), [filters]);
  const filtersList = useMemo(
    () => availableFilters.filter(({ name }) => !usedFilters.includes(name)),
    [availableFilters, usedFilters]
  );
  const enableFilterAdd = filtersList.length > 0 && filters.length < availableFilters.length;

  return (
    <FilterCard>
      {filters.map(
        (
          { label, name, removable = true, component: Component, valueComponent: ValueComponent, width, ...otherProps },
          key
        ) => (
          <FilterItem
            key={key}
            name={t(label)}
            namePlaceholder={t('consignments:filter.button.name_placeholder')}
            valuePlaceholder={
              (ValueComponent && (
                <ValueComponent placeholder={t('consignments:filter.button.value_placeholder')} {...otherProps} />
              )) ||
              t('consignments:filter.button.value_placeholder')
            }
            nameSlot={<FilterTypeList filtersList={filtersList} onChange={onFilterTypeChange(key, name)} />}
            valueSlot={Component && <Component name={name} onChange={onFilterValueChange(key, name)} {...otherProps} />}
            removeButtonLabel={t('consignments:filter.remove_button')}
            onRemoveClick={onRemoveFilter(key, name)}
            containerWidth={width}
            showRemoveButton={removable}
          />
        )
      )}
      <AddButton
        variant="plain"
        leftIcon={faPlusCircle}
        disabled={!enableFilterAdd}
        inline
        onClick={() => setFilters([...filters, {}])}
      >
        {t('consignments:filter.add_button')}
      </AddButton>
    </FilterCard>
  );
}
