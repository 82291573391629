/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Input, Text, Button } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { actions as userActions } from '../../store/user';
import LoginContainer from '../../containers/LoginContainer';
import Alerts from '../../containers/Alerts';
import useAuthCheck from '../../utils/hooks/useAuthCheck';
import config from '../../config';

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(config.policy.username, { message: 'validations:username.invalid', excludeEmptyString: true })
    .required(),
  password: yup.string().required(),
});

export default function Login() {
  const [username, setUsername] = useState('');

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isLoggedIn, pathname] = useAuthCheck();

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  // init user if logged in
  useEffect(() => {
    !isLoggedIn && dispatch(userActions.init());
  }, [dispatch, isLoggedIn]);

  if (isLoggedIn) {
    return <Redirect to={pathname} />;
  }

  const onSubmit = ({ username, password }) => {
    dispatch(userActions.login(username, password, true));
    // lose form focus after login, to avoid user from pressing enter
    document.activeElement.blur();
  };

  return (
    <LoginContainer title={t('sign_in')}>
      <Text className="subtext">{t('sign_in_subtext')}</Text>
      <Alerts namespace="login" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="inputContainer">
          <Input
            size="large"
            label={t('username')}
            name="username"
            value={username}
            autoComplete="username"
            onChange={setUsername}
            ref={register}
            errors={i18n.t(errors.username?.message)}
            tabIndex="1"
          />
          <Input
            size="large"
            label={t('password')}
            type="password"
            name="password"
            autoComplete="current-password"
            ref={register}
            errors={i18n.t(errors.password?.message)}
            tabIndex="2"
          >
            <Link className="subdued" to={{ pathname: '/forgot-password', state: { username } }}>
              {t('forgot_password')}
            </Link>
          </Input>
        </div>
        <div className="alignCenter">
          <Button variant="primary" inline large tabIndex="3">
            {t('sign_in')}
          </Button>
        </div>
      </form>
    </LoginContainer>
  );
}
