import { InvalidTokenError } from 'jwt-decode';

export default class TokenExpired extends InvalidTokenError {
  constructor(config) {
    super('Token Expired');

    this.lastConfig = config;
  }

  getLastConfig() {
    return this.lastConfig;
  }
}
