import dateFnsFormat from 'date-fns/format';

import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';

const locales = { de, en };

export const getLocale = (locale) => (locale in locales ? locales[locale] : locales.en);

export const formatDay = (date, locale) => {
  const selectedLocale = getLocale(locale);
  return dateFnsFormat(date, 'd', { locale: selectedLocale });
};

export const formatMonthTitle = (date, locale) => {
  const selectedLocale = getLocale(locale);
  return dateFnsFormat(date, 'MMMM y', { locale: selectedLocale });
};

export const formatWeekdayLong = (number, locale) => {
  const selectedLocale = getLocale(locale);
  return selectedLocale.localize.day(number);
};

export const formatWeekdayShort = (number, locale) => {
  const selectedLocale = getLocale(locale);
  return selectedLocale.localize.day(number, { width: 'short' });
};

export const getFirstDayOfWeek = (locale) => {
  return 0;
};

export const getMonths = (locale) => {
  const selectedLocale = getLocale(locale);
  return Array(12)
    .fill(null)
    .map((_, i) => selectedLocale.localize.month(i));
};
