import produce from 'immer';
import { types as actionTypes } from './actions';

const openPopup = (state, { namespace, title, message, submitCallback, closeCallback }) => {
  state[namespace] = { title, message, submitCallback, closeCallback };
  return state;
};

const closePopup = (state, { namespace }) => {
  state[namespace] = null;
  return state;
};

const reducersMap = {
  [actionTypes.OPEN_POPUP]: openPopup,
  [actionTypes.CLOSE_POPUP]: closePopup,
};

const initialState = {};

export default (state = initialState, { type: actionType, payload }) =>
  produce(state, (draft) => (actionType in reducersMap && reducersMap[actionType](draft, payload)) || state);
