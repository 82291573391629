import React from 'react';
import PropTypes from 'prop-types';

export default function SelectValue({ value, placeholder }) {
  return (
    <>
      {!value && placeholder}
      {value && value.map((v) => ' ' + v.label)}
    </>
  );
}

SelectValue.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};
