import * as yup from 'yup';

const BASE_KEY = 'consignments:unit_quantities';
const schema = (consignment) =>
  yup.lazy((data) => {
    const { quantities = {} } = consignment;

    const validations = {
      total_parcels_quantity: yup
        .number()
        .integer()
        .min(0)
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
      actual_picked_up: yup.date(),
      actual_drop_off: yup.date(),
    };

    let canEnterPickedUp = true;
    const availableKeys = Object.keys(data[BASE_KEY]).filter((key) => key in quantities);

    // set default validations for each type
    availableKeys.forEach((key) => {
      if (!isNaN(parseInt(data[BASE_KEY][key].planned_quantity))) {
        canEnterPickedUp =
          canEnterPickedUp && String(data[BASE_KEY][key].planned_quantity) === String(quantities[key].planned_quantity);
      }
      validations[key] = {
        planned_quantity: yup.number(),
        picked_up_quantity: yup.number(),
        dropped_off_quantity: yup.number(),
      };
    });

    // finalise validation set
    availableKeys.forEach((key) => {
      if (!canEnterPickedUp) {
        delete validations[key].picked_up_quantity;
        delete validations.actual_picked_up;
      }
      validations[key] = yup.object().shape(validations[key]);
    });

    return yup.object().shape({
      [BASE_KEY]: yup.object().shape(validations),
    });
  });

export default schema;
