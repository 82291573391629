export const types = {
  ADD_ALERT: 'ADD_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',
  CLEAR_ALERTS: 'CLEAR_ALERTS',
};

export const add = (namespace, type, message) => ({
  type: types.ADD_ALERT,
  payload: { namespace, type, message },
});

export const remove = (namespace, index) => ({
  type: types.REMOVE_ALERT,
  payload: { namespace, index },
});

export const clear = (namespace) => ({
  type: types.CLEAR_ALERTS,
  payload: { namespace },
});
