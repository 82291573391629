import produce from 'immer';
import { types as actionTypes } from './actions';
import { denormalizeConsignments, normalizeConsignment, normalizeConsignments } from './schema';

const setConsignments = (state, consignments) => {
  state.loading = false;

  if (consignments) {
    consignments.forEach((c) => {
      if (c.direction === 'outbound') {
        if (state.outboundPickupStations.findIndex((p) => p.value === c.origin_loading_location.station.id) === -1) {
          state.outboundPickupStations.push({
            value: c.origin_loading_location.station.id,
            label: c.origin_loading_location.station.address.city,
          });
        }
        //lmc
        if (state.outboundLMCs.findIndex((p) => p.value === c.lmc.id) === -1) {
          state.outboundLMCs.push({
            value: c.lmc.id,
            label: c.lmc.public_name,
          });
        }
      } else {
        if (state.inboundPickupStations.findIndex((p) => p.value === c.origin_loading_location.station.id) === -1) {
          state.inboundPickupStations.push({
            value: c.origin_loading_location.station.id,
            label: c.origin_loading_location.station.address.city,
          });
        }
        //lmc
        if (state.inboundLMCs.findIndex((p) => p.value === c.lmc.id) === -1) {
          state.inboundLMCs.push({
            value: c.lmc.id,
            label: c.lmc.public_name,
          });
        }
      }
    });

    state.normalized = normalizeConsignments(consignments);
    state.consignments = denormalizeConsignments(state.normalized.result, state.normalized.entities);
  } else {
    state.normalized = null;
    state.consignments = null;
  }

  return state;
};

const setConsignment = (state, consignment) => {
  state.consignment = consignment ?? null;
  state.loadingConsignment = false;

  if (state.consignment && !('syncedAt' in state.consignment)) {
    updateConsignment(state, consignment);
  }

  return state;
};

const setLoadingListState = (state, isLoading) => {
  state.loading = isLoading;
  return state;
};

const setLoadingConsignmentState = (state, isLoading) => {
  state.loadingConsignment = isLoading;
  return state;
};

const updateConsignment = (state, consignment) => {
  const {
    entities: { consignment: updatedConsignment },
  } = normalizeConsignment(consignment);

  const { id } = consignment;
  state.consignment = updatedConsignment[id];

  if (!state.normalized) {
    return state;
  }

  const {
    entities: { consignment: consignments },
  } = state.normalized;

  if (id in consignments) {
    const {
      entities: { consignment: updatedConsignment },
    } = normalizeConsignment(consignment);
    consignments[id] = updatedConsignment[id];
    state.consignments = denormalizeConsignments(state.normalized.result, state.normalized.entities);
  }

  return state;
};

const addFilter = (key) => (state, filter) => {
  const filters = state.filters[key];
  const { name, value } = filter;
  filters[name] = value;
  return state;
};

const removeFilter = (key) => (state, filterName) => {
  const filters = state.filters[key];

  if (filterName in filters) {
    delete filters[filterName];
  }

  return state;
};

const setFilters = (key) => (state, filters) => {
  state.filters[key] = filters;
  return state;
};

const reducersMap = {
  [actionTypes.SET_CONSIGNMENT]: setConsignment,
  [actionTypes.SET_CONSIGNMENTS]: setConsignments,
  [actionTypes.SET_LOADING_CONSIGNMENTS]: setLoadingListState,
  [actionTypes.SET_LOADING_CONSIGNMENT]: setLoadingConsignmentState,
  [actionTypes.UPDATE_CONSIGNMENT]: updateConsignment,
  [actionTypes.ADD_OUTBOUND_FILTER]: addFilter('outbound'),
  [actionTypes.ADD_INBOUND_FILTER]: addFilter('inbound'),
  [actionTypes.REMOVE_OUTBOUND_FILTER]: removeFilter('outbound'),
  [actionTypes.REMOVE_INBOUND_FILTER]: removeFilter('inbound'),
  [actionTypes.SET_OUTBOUND_FILTERS]: setFilters('outbound'),
  [actionTypes.SET_INBOUND_FILTERS]: setFilters('inbound'),
};

const initialState = {
  consignments: null,
  consignment: null,
  outboundPickupStations: [],
  inboundPickupStations: [],
  outboundLMCs: [],
  inboundLMCs: [],
  normalized: null,
  loading: false,
  loadingConsignment: false,
  filters: {
    inbound: {},
    outbound: {},
  },
};

export default (state = initialState, { type: actionType, payload }) =>
  produce(state, (draft) => (actionType in reducersMap && reducersMap[actionType](draft, payload)) || state);
