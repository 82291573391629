import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  AvatarInitials,
  SideNavigation,
  NavigationItem,
  Loader,
  ContextMenuItem,
  Text,
  ContextMenuSeparator,
  ContextMenu,
  useOutsideClick,
} from '@sevensenders/component-library';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as InboundIcon } from '../../icons/navigation-inbound.svg';
import { ReactComponent as OutboundIcon } from '../../icons/navigation-outbound.svg';
import { ReactComponent as UserIcon } from '../../icons/user-icon.svg';

import './page-container.scss';
import { actions as userActions } from '../../store/user';

const SETTINGS_MENU = 1;

const SettingsMenu = React.forwardRef(function Settings(props, ref) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const name = useSelector((state) => state.user.name);
  const { onMenuItemClick } = props;

  return (
    <ContextMenu ref={ref} className="settingsMenu">
      <ContextMenuItem className="userDetails" onClick={(e) => e.stopPropagation()} disabled>
        <AvatarInitials content={name} />
        <div className="userName">
          <Text>{name}</Text>
          <Text secondary small>
            Admin
          </Text>
        </div>
      </ContextMenuItem>
      <ContextMenuSeparator />
      <ContextMenuItem onClick={onMenuItemClick(() => history.push('/settings'))}>
        <Text>{t('settings')}</Text>
      </ContextMenuItem>
      <ContextMenuSeparator />
      <ContextMenuItem onClick={() => dispatch(userActions.logout())}>
        <Text>{t('logout')}</Text>
      </ContextMenuItem>
    </ContextMenu>
  );
});

SettingsMenu.propTypes = {
  onMenuItemClick: PropTypes.func.isRequired,
};

const outboundRoute = '/consignments/outbound';
const inboundRoute = '/consignments/inbound';
const settingsRoute = '/settings';

function TopMenu() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <NavigationItem
        active={pathname.includes(outboundRoute)}
        onClick={() => history.push(outboundRoute)}
        aria-label={t('nav.outbound_menu')}
      >
        <OutboundIcon />
      </NavigationItem>
      <NavigationItem
        active={pathname.includes(inboundRoute)}
        onClick={() => history.push(inboundRoute)}
        aria-label={t('nav.inbound_menu')}
      >
        <InboundIcon />
      </NavigationItem>
    </>
  );
}

function BottomMenu() {
  const [activeNav, setActiveNav] = useState();
  const settingsMenuRef = useRef();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useOutsideClick(settingsMenuRef, () => {
    setActiveNav(null);
  });

  const toggleNav = useCallback(
    (index) => (e) => {
      setActiveNav(activeNav === index ? null : index);
    },
    [activeNav, setActiveNav]
  );

  const handleMenuItemClick = useCallback(
    (fn) => (e) => {
      fn && fn(e);
      setActiveNav(null);
    },
    [setActiveNav]
  );

  return (
    <span ref={settingsMenuRef}>
      <NavigationItem
        active={pathname.includes(settingsRoute)}
        onClick={toggleNav(SETTINGS_MENU)}
        aria-label={t('nav.user_menu')}
      >
        <UserIcon />
      </NavigationItem>
      {activeNav === SETTINGS_MENU && <SettingsMenu onMenuItemClick={handleMenuItemClick} />}
    </span>
  );
}

function LogoLink({ children }) {
  const { t } = useTranslation();
  return (
    <Link to="/" aria-label={t('nav.homepage')}>
      {children}
    </Link>
  );
}

LogoLink.propTypes = {
  children: PropTypes.node.isRequired,
};

function PageContainer({ children, className }) {
  const isLoading = useSelector((state) => state.loader.loading);
  const containerClasses = classnames('App', className);

  return (
    <div className={containerClasses}>
      <SideNavigation logoWrapper={LogoLink} top={<TopMenu />} bottom={<BottomMenu />} />
      <main className={classnames({ loading: isLoading })}>{children}</main>
      {isLoading && <Loader />}
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default React.memo(PageContainer);
