import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import * as DateFNSLocaleUtils from '../../locales/date-util';
import isValid from 'date-fns/isValid';

/**
 * Returns translator function for date and formats it under locale
 * @param width short, medium, long
 * @returns {function(*=): string | *}
 */
export default function useDateLocale(width = 'medium') {
  const {
    i18n: { language },
  } = useTranslation();
  const locale = DateFNSLocaleUtils.getLocale(language);
  const dateFormat = useMemo(() => locale.formatLong.date({ width }), [locale, width]);

  return useCallback((date) => (isValid(date) ? format(date, dateFormat, { locale }) : null), [dateFormat, locale]);
}
