export const types = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP',
};

export const open = (namespace, title, message, submitCallback, closeCallback) => ({
  type: types.OPEN_POPUP,
  payload: { namespace, title, message, submitCallback, closeCallback },
});

export const close = (namespace) => ({
  type: types.CLOSE_POPUP,
  payload: { namespace },
});
