import React, { useMemo, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import QuantityForm from '../../QuantityForm';
import EditableCell from '../../../components/EditableCell';
import { ConsignmentContext } from '../../Consignments/context';

export default function QuantityEditCell({ value, row, column }) {
  const cellRef = useRef();
  const dispatch = useDispatch();
  const { type } = useContext(ConsignmentContext);
  const { original: rowData } = row;
  const { subText, isEditable: isEditableCallback, onSubmit } = column;
  const hasValue = value != null && typeof value != 'undefined';
  const editable = useMemo(
    () => isEditableCallback && isEditableCallback({ type, rowData, column }),
    [isEditableCallback, rowData, column, type]
  );

  const onCancel = useCallback(() => {
    cellRef.current && cellRef.current.dispatchEvent(new Event('close'));
  }, [cellRef]);

  const handleSubmit = useCallback(
    (value) => {
      onSubmit && onSubmit(dispatch)({ type, rowData, column, value });
      onCancel();
    },
    [onSubmit, dispatch, rowData, column, onCancel, type]
  );

  return (
    <EditableCell
      ref={cellRef}
      editable={editable}
      value={hasValue ? value : <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }} />}
      subText={hasValue || editable ? subText : ''}
    >
      <QuantityForm value={value} subText={subText} onCancel={onCancel} onSubmit={handleSubmit} />
    </EditableCell>
  );
}

QuantityEditCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.any,
  row: PropTypes.any,
};
