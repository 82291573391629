import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sevensenders/component-library';

export default function CustomersCell({ value }) {
  if (!value) {
    return null;
  }

  //filter offerRelationByName
  const filteredValue = value.filter(
    (obj, index) => value.findIndex((item) => item.shipper.public_name === obj.shipper.public_name) === index
  );

  return (
    <>
      {filteredValue.map((v, k) => (
        <Text secondary small inline={false} key={k}>
          {' '}
          {v.shipper.public_name}{' '}
        </Text>
      ))}
    </>
  );
}

CustomersCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      shipper: PropTypes.shape({
        public_name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
};
