import { denormalize, normalize, schema } from 'normalizr';

const consignmentEntity = new schema.Entity(
  'consignment',
  {},
  {
    processStrategy: (entity) => {
      // add lmc if
      if (!('lmc' in entity) || !entity.lmc) {
        entity.lmc = { public_name: '' };
      }

      const draft = { ...entity };
      draft.syncedAt = Date.now();

      // move quantities to top level;
      draft.quantities = {};
      draft.unit_quantities &&
        draft.unit_quantities.length > 0 &&
        draft.unit_quantities.forEach((quantity) => {
          const {
            dispatch_unit_type: { short_name },
          } = quantity;
          draft.quantities[short_name] = quantity;
        });

      draft.vehicle_license = {
        pickup: draft.license_plates[0] || null,
        drop_off: draft.license_plates[draft.license_plates.length - 1] || null,
      };

      return draft;
    },
  }
);
const consignmentsSchema = [consignmentEntity];

export const denormalizeConsignments = (input, entities) => denormalize(input, consignmentsSchema, entities);
export const normalizeConsignment = (consignment) => normalize(consignment, consignmentEntity);
export const normalizeConsignments = (consignments) => normalize(consignments, consignmentsSchema);
