import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from '@sevensenders/component-library';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Footer = styled.div`
  text-align: right;
  padding: 12px 24px;
  border-top: 1px solid var(--colors-isabeline);

  button:last-child {
    margin-left: 12px;
  }
`;

const StyledSelect = styled(Select)`
  display: block !important;
`;

export default function SelectFilter({ onChange, value, direction }) {
  const [userChoice, setUserChoice] = useState();
  const { t } = useTranslation();
  const stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  let { outboundLMCs, inboundLMCs } = useSelector((state) => state.consignments);
  let LMCs = direction === 'outbound' ? outboundLMCs : inboundLMCs;

  const clearFilter = (e) => {
    onChange && onChange(null);
    setUserChoice(null);
  };

  const applyFilter = () => {
    onChange && onChange(userChoice);
  };

  let otherProps = {
    isMulti: true,
    closeMenuOnSelect: true,
    showCheckboxes: true,
    showValuesInline: true,
    debounce: 650,
  };

  if (value) {
    otherProps.value = value;
  }

  return (
    <div>
      <div role="button" tabIndex={-1} onClick={stopPropagation} onKeyPress={stopPropagation}>
        <StyledSelect options={LMCs} onChange={setUserChoice} {...otherProps} />
      </div>
      <Footer>
        <Button variant="plain" inline onClick={clearFilter}>
          {t('clear')}
        </Button>
        <Button variant="primary" inline onClick={applyFilter}>
          {t('apply')}
        </Button>
      </Footer>
    </div>
  );
}

SelectFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  direction: PropTypes.string,
};
