import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from '@sevensenders/component-library';

import { actions as alertActions } from '../../store/alerts';

import './alerts.scss';

export default function Alerts({ namespace }) {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts[namespace]);
  const classes = classNames('alerts', namespace);
  const { t } = useTranslation();

  const onCloseClick = (index) => () => {
    dispatch(alertActions.remove(namespace, index));
  };

  // clear all on unmount
  useEffect(
    () => () => {
      dispatch(alertActions.clear(namespace));
    },
    [dispatch, namespace]
  );

  return (
    <div className={classes}>
      {alerts &&
        alerts.length > 0 &&
        alerts.map(({ type, message }, key) => (
          <Alert key={key} message={t(message)} type={type} onCloseClick={onCloseClick(key)} />
        ))}
    </div>
  );
}

Alerts.propTypes = {
  namespace: PropTypes.string.isRequired,
};
