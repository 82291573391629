import React from 'react';
import { TableRow } from '@sevensenders/component-library';

// import ConsignmentRowWarning from '../../components/ConsignmentRowWarning';
// import ConsignmentRowException from '../../components/ConsignmentRowException';

const statusRowMapping = {
  default: React.memo(TableRow),
};

export const getRowComponentByStatus = (row) => {
  const { status } = row;
  if (status in statusRowMapping) {
    return statusRowMapping[status];
  }
  return statusRowMapping.default;
};
