import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker } from '@sevensenders/component-library';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { formatDatetime } from '../../../../utils/datetime';

const Footer = styled.div`
  text-align: right;
  padding: 12px 24px;
  border-top: 1px solid var(--colors-isabeline);

  button:last-child {
    margin-left: 12px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  display: block !important;
`;

export default function DateRangePickerFilter({ onChange, value, normalize, denormalize }) {
  const [dateRange, setDateRange] = useState();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const clearFilter = (e) => {
    setDateRange(null);
    onChange && onChange(null);
  };

  const applyFilter = () => {
    if (!dateRange) {
      return;
    }

    const { from, to } = dateRange;
    onChange &&
      onChange(
        denormalize({
          from: formatDatetime(from),
          to: formatDatetime(to),
        })
      );
  };

  let dateProps = { from: null, to: null };
  if (value) {
    const { from, to } = normalize(value);
    dateProps.from = new Date(from);
    dateProps.to = new Date(to);
  }

  return (
    <div>
      <div role="button" tabIndex={-1} onClick={stopPropagation} onKeyPress={stopPropagation}>
        <StyledDatePicker numberOfMonths={1} onChange={setDateRange} locale={language} {...dateProps} />
      </div>
      <Footer>
        <Button variant="plain" inline onClick={clearFilter}>
          {t('clear')}
        </Button>
        <Button variant="primary" inline onClick={applyFilter}>
          {t('apply')}
        </Button>
      </Footer>
    </div>
  );
}

DateRangePickerFilter.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  normalize: PropTypes.func,
  denormalize: PropTypes.func,
};
