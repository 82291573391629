import React from 'react';
import PropTypes from 'prop-types';
import { SelectInline } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';

export function FilterTypeList(props) {
  const { filtersList, onChange } = props;
  const { t } = useTranslation();

  return (
    <SelectInline
      label={t('consignments:filter.button.default')}
      showLabel={false}
      options={filtersList}
      onChange={onChange}
      placeholder={t('consignments:filter.column_placeholder')}
      clearInputValue={false}
    />
  );
}

FilterTypeList.propTypes = {
  filtersList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
