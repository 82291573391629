import React, { useState, useCallback, useEffect } from 'react';
import { H2, H3, Text, Button, RadioButton } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';

const SettingsCard = styled(Card)`
  padding-bottom: 32px;

  .UserLangSettings__option {
    margin-bottom: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default function UserSettings() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [lang, setLang] = useState(language);

  useEffect(() => {
    setLang(language);
  }, [setLang, language]);

  const onSave = useCallback(async () => {
    await i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const onLanguageChange = useCallback(
    (lang) => {
      setLang(lang);
    },
    [setLang]
  );

  return (
    <>
      <PageHeader>
        <H2>{t('sub_settings.user')}</H2>
        <Button variant="primary" onClick={onSave} inline>
          {t('action.save_settings')}
        </Button>
      </PageHeader>
      <SettingsCard>
        <H3>{t('language.title')}</H3>
        <Text className="Card__subText" secondary>
          {t('language.settings_subtext')}
        </Text>
        <div className="UserLangSettings__option">
          <RadioButton name="language" label="English" value="en" checked={lang !== 'de'} onChange={onLanguageChange} />
        </div>
        <div className="UserLangSettings__option">
          <RadioButton name="language" label="Deutsch" value="de" checked={lang === 'de'} onChange={onLanguageChange} />
        </div>
      </SettingsCard>
    </>
  );
}
