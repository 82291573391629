/**
 * Get scroll of el + also scroll w.r.t to parent node
 * @credits https://stackoverflow.com/a/11701750
 * @param el
 * @returns {{left: number, top: number}}
 */
export function getScroll(el) {
  let left = 0;
  let top = 0;

  if (el && el.parentNode) {
    while (el) {
      if (el && el.parentNode) {
        left += el.scrollLeft;
        top += el.scrollTop;
        el = el.parentNode;
      } else {
        el = null;
      }
    }
  }

  return { left, top };
}
