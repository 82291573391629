import API, { createCancelToken } from '../../api';

export const types = {
  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
  SET_SETTINGS_LOADING: 'SET_SETTINGS_LOADING',
};

const setNotificationSettings = (settings) => ({
  type: types.SET_NOTIFICATION_SETTINGS,
  payload: settings,
});

const setLoading = (isLoading) => ({
  type: types.SET_SETTINGS_LOADING,
  payload: isLoading,
});

export const getCustomerSettings = () => (dispatch) => {
  const canceller = createCancelToken();

  dispatch(setLoading(true));

  API.getCustomerSettings(canceller)
    .then(({ data }) => {
      dispatch(setNotificationSettings(data));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });

  return canceller;
};

export const updateCustomerSettings = (data) => (dispatch) => {
  const canceller = createCancelToken();

  dispatch(setLoading(true));

  API.updateCustomerSettings(data, canceller)
    .then(({ data }) => {
      dispatch(setNotificationSettings(data));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });

  return canceller;
};
