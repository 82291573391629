import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sevensenders/component-library';

export default function StationCell({ value }) {
  if (!value) {
    return null;
  }

  const {
    address: { city },
    partner: { public_name },
  } = value;

  return (
    <>
      <Text inline={false}>{city}</Text>
      <Text secondary small inline={false}>
        {public_name}
      </Text>
    </>
  );
}

StationCell.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
    }).isRequired,
    partner: PropTypes.shape({
      public_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
