import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button, Input, useEffectOnce } from '@sevensenders/component-library';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getNumberValue } from '../../utils/consignments';

const Form = styled.form`
  display: flex;
  padding: 12px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px var(--colors-snow), 0 2px 16px 0 rgba(45, 50, 51, 0.1);
  background-color: var(--colors-white);

  input[name='quantity'] {
    width: 74px;
  }

  > button[type='reset'] {
    margin: 0 12px;
  }
`;

const schema = yup.object().shape({
  quantity: yup.number().required().integer(),
});

export default function QuantityForm({ className, subText, value, onSubmit, onCancel }) {
  const { t, i18n } = useTranslation();
  const inputRef = useRef();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  // focus on mount
  useEffectOnce(() => {
    inputRef && inputRef.current && inputRef.current.focus();
  });

  const onInputChange = useCallback(
    (value) => {
      inputRef.current.value = getNumberValue(value);
    },
    [inputRef]
  );

  const onFormSubmit = useCallback(
    ({ quantity }) => {
      onSubmit && onSubmit(quantity);
    },
    [onSubmit]
  );

  return (
    <Form className={className} onSubmit={handleSubmit(onFormSubmit)}>
      <Input
        ref={(e) => {
          register(e);
          inputRef.current = e;
        }}
        defaultValue={value}
        autoComplete="off"
        label="Quantity"
        name="quantity"
        showLabel={false}
        type="text"
        postfixText={subText || t('unit')}
        errors={errors.quantity?.message}
        showErrors={false}
        onChange={onInputChange}
        inputMode="numeric"
      />
      <Button inline variant="plain" onClick={onCancel} type="reset">
        {t('cancel')}
      </Button>
      <Tooltip
        tip={i18n.t(errors.quantity?.message)}
        place="bottom"
        globalEventOff="click"
        event="mousemove"
        eventOff="click"
      >
        <Button inline variant="primary">
          {t('submit')}
        </Button>
      </Tooltip>
    </Form>
  );
}

QuantityForm.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  subText: PropTypes.string,
};
