import produce from 'immer';
import { types as actionTypes } from './actions';

const setDetails = (state, details) => {
  const { roles, name } = details;
  state.logged = true;
  state.roles = roles;
  state.name = name;

  return state;
};

const setToken = (state, { token, refreshToken = null }) => {
  state.token = token;
  refreshToken && (state.refreshToken = refreshToken);

  return state;
};

const logout = () => {
  return initialState;
};

const reducersMap = {
  [actionTypes.SET_TOKEN]: setToken,
  [actionTypes.LOGOUT]: logout,
  [actionTypes.SET_USER_DETAILS]: setDetails,
};

const initialState = {
  logged: false,
  token: null,
  refreshToken: null,
  roles: null,
  name: null,
};

export default (state = initialState, { type: actionType, payload }) =>
  produce(state, (draft) => (actionType in reducersMap && reducersMap[actionType](draft, payload)) || state);
