import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sevensenders/component-library';

export default function DestinationCell({ value }) {
  if (!value) {
    return null;
  }

  const {
    address: { city, country },
  } = value;

  return (
    <>
      <Text inline={false}>{city}</Text>
      <Text secondary small inline={false}>
        {country}
      </Text>
    </>
  );
}

DestinationCell.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
