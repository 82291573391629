import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

export default function AuthenticatedRoute({ isAuthenticated, loginPath, ...props }) {
  const location = useLocation();

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: loginPath, state: { from: location } }} />;
  }

  return <Route {...props} />;
}

AuthenticatedRoute.propTypes = {
  loginPath: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
