import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Modal, H3, Text, Button } from '@sevensenders/component-library';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../icons/close.svg';

const StyledModal = styled(Modal)`
  .modal-wrapper {
    border-radius: 4px;
    width: 600px;
  }

  .modal-close {
    padding: 0;
    top: 22px;
    right: 24px;
  }

  .confirmationBox__closeIcon {
    display: block;
  }

  .confirmationBox__header {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 20px 24px;
    box-shadow: inset 0 -1px 0 0 var(--colors-light-grey);
  }

  .confirmationBox__body {
    padding: 24px 26px;
  }

  .confirmationBox__footer {
    padding: 16px 24px;
    box-shadow: inset 0 1px 0 0 var(--colors-light-grey);
    text-align: right;
  }

  .confirmationBox__actionButton {
    margin-left: 8px;
  }
`;

export default function ConfirmationBox({
  submitType,
  className,
  children,
  icon,
  onClose,
  headerTitle,
  onSubmit,
  submitText,
}) {
  const { t } = useTranslation();

  return (
    <StyledModal
      className={className}
      onClose={onClose}
      closeIcon={<CloseIcon className="confirmationBox__closeIcon" />}
    >
      <H3 className="confirmationBox__header">
        {icon}
        {headerTitle}
      </H3>
      <div className="confirmationBox__body">
        <Text>{children}</Text>
      </div>
      <div className="confirmationBox__footer">
        <Button className="confirmationBox__actionButton" inline onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button className="confirmationBox__actionButton" variant={submitType} inline onClick={onSubmit}>
          {submitText}
        </Button>
      </div>
    </StyledModal>
  );
}

ConfirmationBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  icon: PropTypes.any,
  onClose: PropTypes.func,
  headerTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string.isRequired,
  submitType: PropTypes.string.isRequired,
};
