import translation from './default.json';
import validations from './validations.json';
import consignments from './consignments.json';
import server from './server.json';

export default {
  translation,
  validations,
  consignments,
  server,
};
