import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from '@sevensenders/component-library';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import config from '../../config';
import Alerts from '../../containers/Alerts';

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(config.policy.username, { message: 'validations:username.invalid', excludeEmptyString: true })
    .required(),
});

function RequestForm({ username: usernameState = '', onSubmit }) {
  const { t, i18n } = useTranslation();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const [username, setUsername] = useState(usernameState);

  const onFormSubmit = async ({ username }) => {
    onSubmit &&
      onSubmit({
        username,
      });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Alerts namespace="forgot-password" />
      <div className="inputContainer">
        <Input
          size="large"
          label={t('username')}
          name="username"
          value={username}
          onChange={setUsername}
          errors={i18n.t(errors.username?.message)}
          ref={register}
        />
        <Link className="subdued" to="/login">
          {t('sign_in_back')}
        </Link>
      </div>
      <div className="alignCenter">
        <Button variant="primary" inline large>
          {t('reset_password')}
        </Button>
      </div>
    </form>
  );
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func,
  username: PropTypes.string,
};

export default React.memo(RequestForm);
