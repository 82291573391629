import { Card as CI_Card } from '@sevensenders/component-library';
import styled from '@emotion/styled';

const Card = styled(CI_Card)`
  padding: 24px 32px;

  .Card__subText {
    display: block;
    margin-bottom: 16px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export default Card;
