import React from 'react';
import PropTypes from 'prop-types';
import isSameDay from 'date-fns/isSameDay';

import useDateLocale from '../../../utils/hooks/useDateLocale';

export default function DateRangeValue({ value, placeholder }) {
  const dtLocale = useDateLocale();
  let date = '';

  if (value) {
    const { after, before } = value;
    const from = new Date(after);
    const to = new Date(before);
    date = dtLocale(from);
    if (!isSameDay(from, to)) {
      date += ` - ${dtLocale(to)}`;
    }
  }

  return (
    <>
      {(!value || !date) && placeholder}
      {value && date}
    </>
  );
}

DateRangeValue.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    after: PropTypes.string,
    before: PropTypes.string,
  }),
};
